import Error from "./Error";

const Input = ({
  extraClass,
  type,
  maxLength,
  id,
  label,
  value,
  onChange,
  onBlur,
  error,
  disabled,
}) => {
  return (
    <div className={`p-2 ${extraClass ? extraClass : ""}`}>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        aria-describedby={`${id}Error`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <Error id={id} error={error} />
    </div>
  );
};

export default Input;
