import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import StudentSignUp from "../pages/SignUp/Student";
import TutorSignUp from "../pages/SignUp/Tutor";
import Simple from "../pages/Simple";
import Portal from "../pages/Portal";
import CompleteProfile from "../pages/Tutor/CompleteProfile";
import Dashboard from "../pages/Tutor/Dashboard";
import AuthContext from "../context/AuthContext";
import StudentDashboard from "../pages/Student/StudentDashboard";
import SearchedTutors from "../pages/SearchedTutors";
import { HomeSkillContext } from "../context/HomeSkillContext";
import ViewProfile from "../pages/Tutor/ViewProfile";
import StudentUpdateProfile from "../pages/Student/StudentUpdateProfile";
import TutorUpdateProfile from "../pages/Tutor/TutorUpdateProfile";
import PostRequirement from "../pages/Student/PostRequirement";
import Requirements from "../pages/Student/Requirements";
import LoginPage from "../pages/Login";
import CheckTutor from "../pages/Tutor/CheckTutor";
import StudentCredits from "../pages/Student/StudentCredits";
import StudentRequirements from "../pages/StudentRequirements";
import ViewRequirement from "../pages/Student/ViewRequirement";
import TutorCreditLogs from "../pages/Tutor/TutorCreditLogs";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import PaymentToTutor from "../pages/PaymentToTutor";
import RegistrationProcess from "../pages/RegistrationProcess";

const AllRoutes = () => {
  const { authUserData } = useContext(AuthContext);
  console.log("all routes", authUserData);
  return (
    // <Routes>
    //
    //   <Route path="/" element={<Simple />}>
    //
    //     <Route path="tutor/complete-profile" element={<CompleteProfile />} />
    //     <Route
    //       path="tutor/application-under-process"
    //       element={<ApplicationUnderProcess />}
    //     />
    //     <Route path="tutor/job/:jobId" element={<ViewJob />} />
    //     <Route path="tutor/job/search" element={<TutorJobs />} />
    //     <Route element={<HomeSkillContext />}>
    //       <Route path="tutor/search" element={<SearchedTutors />} />
    //     </Route>
    //   </Route>
    // </Routes>
    <Routes>
      {authUserData && authUserData.entityType === "tutor" && (
        <Route path="/portal" element={<Portal />}>
          <Route element={<CheckTutor />}>
            <Route path="tutor/credits" element={<TutorCreditLogs />} />
            <Route path="tutor/dashboard" element={<Dashboard />} />
            <Route
              path="tutor/profile/update"
              element={<TutorUpdateProfile />}
            />
          </Route>
        </Route>
      )}
      {authUserData && authUserData.entityType === "student" && (
        <Route path="/portal" element={<Portal />}>
          <Route path="student/dashboard" element={<StudentDashboard />} />
          <Route
            path="student/profile/update"
            element={<StudentUpdateProfile />}
          />
          <Route
            path="student/post-requirement"
            element={<PostRequirement type="Post" />}
          />
          <Route path="student/requirements" element={<Requirements />} />
          <Route path="student/credits" element={<StudentCredits />} />
        </Route>
      )}
      <Route element={<Simple />}>
        <Route path="/registration-process" element={<RegistrationProcess />} />
        <Route path="/payment-to-tutor" element={<PaymentToTutor />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/tutor/view-profile" element={<ViewProfile />} />
        <Route path="/tutor/complete-profile" element={<CompleteProfile />} />
        {!authUserData && (
          <>
            <Route path="/signup/student" element={<StudentSignUp />} />
            <Route path="/signup/tutor" element={<TutorSignUp />} />
            <Route path="/login" element={<LoginPage isNavigate={true} />} />
          </>
        )}
        <Route
          path="/student/requirement/search"
          element={<StudentRequirements />}
        />
        <Route
          path="/student/requirement/:studentRequirementId"
          element={<ViewRequirement />}
        />
        <Route element={<HomeSkillContext />}>
          <Route path="tutor/search" element={<SearchedTutors />} />
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
