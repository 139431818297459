import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useFormik } from "formik";
import { otpVerifySchema } from "../../validations/otpVerify";
import Input from "../common/Input";
import { studentOtpVerify } from "../../apis/student.apis";
import { tutorOtpVerify } from "../../apis/tutor.apis";

const VerifyComponent = ({
  entityType,
  email,
  fullHash,
  toRoute,
  isNavigate,
}) => {
  const { setLocalStorageToken, getAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const verifyClicked = async (values) => {
    console.log("verify clicked toroute", toRoute);
    const { otp } = values;
    let res;
    if (entityType === "student") {
      res = await studentOtpVerify({ email, fullHash, otp });
    } else {
      res = await tutorOtpVerify({ email, fullHash, otp });
    }
    const { data, isError } = res;
    if (!isError) {
      setLocalStorageToken(data.token, entityType);
      await getAuthUserData();
      if (isNavigate) {
        navigate(toRoute);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpVerifySchema,
    onSubmit: verifyClicked,
  });

  return (
    <div className="d-flex justify-content-center align-items-center flex-column shadow p-4 border-top w-25 my-4 mx-auto">
      <div>
        <span className="fs-4 fw-bolder">Please enter OTP</span>
        <br />
        <span className="fs-5">{`(OTP sent on e-mail id)`}</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          type="text"
          id="otp"
          value={formik.values.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.otp && formik.errors.otp ? formik.errors.otp : ""
          }
        />
        <div class="d-flex justify-content-center">
          <button className="btn btn-primary" type="submit">
            Verify
          </button>
        </div>
        {/* <div className="mt-4">
          <span>
            Don't get OTP? <span className="text-primary">Resend OTP</span>
          </span>
        </div> */}
      </form>
    </div>
  );
};

export default VerifyComponent;
