import React from "react";
import { Link } from "react-router-dom";

let description = `Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Mollitia nihil repellat praesentium voluptatibus, nesciunt at
                architecto similique magni veritatis blanditiis culpa ipsa iste,
                hic officia quis. Cupiditate unde enim blanditiis sunt commodi
                exercitationem mollitia explicabo praesentium, laboriosam dolore
                illum asperiores obcaecati sapiente doloremque esse id
                molestiae, aut impedit officia! Tempora numquam qui beatae,
                neque odit modi temporibus ut a blanditiis in omnis illo quos
                dolore iste laudantium dicta nobis soluta? Sint velit vero earum
                dolore? Optio animi in natus consectetur quibusdam mollitia esse
                iure ad, similique velit amet eveniet laudantium. Earum
                accusantium consequatur molestias dicta, iste incidunt quam
                adipisci voluptatem`;

const TutorList = ({ tutor }) => {
  return (
    <div className="d-flex justify-content-center mb-4">
      <div className="w-75">
        <div className="row ">
          <div className="col-12">
            <span className="fs-4 fw-semibold me-2 text-success">
              <Link to={`/tutor/view-profile?tutor=${tutor._id}`}>
                {tutor.name.split(" ")[0]}
              </Link>
            </span>
            <span>{`${tutor.briefDescription}`}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {tutor.teachingDetails.map(({ skill }, index) => {
              return (
                <div
                  key={index}
                  className="me-2 border px-1 fw-semibold d-inline-block mb-2"
                >
                  {skill.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <p>{`${tutor?.introAboutYourself.trim().slice(0, 500)}...`}</p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <span
              className="me-2 border p-1 rounded shadow bg-warning fw-semibold"
              style={{ fontSize: "14px" }}
            >
              {`${tutor.city}, ${tutor.state}, ${tutor.country}`}
            </span>
            {/* <span
              className="me-2 border p-1 rounded shadow bg-warning fw-semibold"
              style={{ fontSize: "14px" }}
            >
              {`${tutor.minFee} - ${tutor.maxFee}`}
            </span>
            <span
              className="me-2 border p-1 rounded shadow bg-secondary-subtle fw-semibold"
              style={{ fontSize: "14px" }}
            >
              Experience
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorList;
