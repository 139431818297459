import React, { useContext } from "react";
import CompleteProfile from "../Tutor/CompleteProfile";
import AuthContext from "../../context/AuthContext";

const StudentDashboard = () => {
  const { authUserData } = useContext(AuthContext);
  return (
    <div
      className="rounded"
      style={{ margin: "16px", backgroundColor: "white", padding: "8px" }}
    >
      {`Welcome ${authUserData.student.name }`}
    </div>
  );
};

export default StudentDashboard;
