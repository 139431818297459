import moment from "moment";
import React from "react";

const ExperienceDetailListItem = ({
  data,
  index,
  onEditClick,
  onDeleteClick,
}) => {
  let dateString = "";
  if (data.startDate) {
    if (data.endDate) {
      dateString = ` (${moment(data.startDate).format("MMM-YYYY")} - ${moment(
        data.endDate
      ).format("MMMM-YYYY")})`;
    } else {
      dateString = ` (${moment(data.startDate).format(
        "MMMM-YYYY"
      )} - till date)`;
    }
  }
  return (
    <div className="teaching-detail-list-item d-flex justify-content-between align-items-center">
      <div>
        <p className="p-0 m-0">{`${data.organization} (${data.designation})`}</p>
        <p className="p-0 m-0">{`${data.association.name}${dateString}`}</p>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm me-2"
          onClick={() => {
            console.log("Edit clicked");
            onEditClick(data, index);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-sm me-2"
          onClick={() => {
            console.log("On Delete Clicked");
            onDeleteClick(index);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ExperienceDetailListItem;
