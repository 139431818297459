import React from "react";

const Select = ({
  label,
  elementId,
  optionsArray,
  value,
  onChange,
  onBlur,
  error,
  disabled,
}) => {
  return (
    <div className="p-2">
      <label htmlFor={elementId}>{label}</label>
      <select
        id={elementId}
        value={value}
        className="form-select"
        aria-label="Default select example"
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        <option key={`${elementId}_#`} value="#">
          {`--Select ${label}--`}
        </option>
        {optionsArray.map(({ _id, name }) => {
          return (
            <option key={`${elementId}_${_id}`} value={`${_id}`}>
              {name}
            </option>
          );
        })}
      </select>
      <div id={`${elementId}Error`}>
        <span className="error">{error}</span>
      </div>
    </div>
  );
};

export default Select;
