import React, { useState } from "react";
import Input from "../../common/Input";
import SelectType3 from "../../common/SelectType3";
import { qualificationDetailsSchema } from "../../../validations/tutor";
import { useFormik } from "formik";
import { getAssociations, getDegreeTypes } from "../../../apis/other.apis";

const QualificationDetailFrom = ({
  setShowQualificationDetailsForm,
  btnText,
  editData,
  formik,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [associations, setAssociations] = useState([]);
  const [degreeTypes, setDegreeTypes] = useState([]);

  const qualificationDetail = useFormik({
    initialValues: editData
      ? editData
      : {
          institutionName: "",
          degreeType: { _id: "#", name: "#" },
          degreeName: "",
          startDate: "",
          endDate: "",
          association: { _id: "#", name: "#" },
          speciality: "",
          score: 0,
        },
    validationSchema: qualificationDetailsSchema,
    onSubmit: () => {
      if (btnText === "ADD") onADD();
      else onSAVE();
      setShowQualificationDetailsForm(false);
    },
  });

  const onSelectType3Change = (e) => {
    let [_id, name] = e.target.value.split(":");
    qualificationDetail.setFieldValue(`${e.target.id}`, {
      _id: Number(_id),
      name,
    });
  };
  const onSelectType3Blur = (e) => {
    qualificationDetail.setFieldTouched(
      `${e.target.id}`,
      { _id: true, name: true },
      true
    );
  };

  const onADD = () => {
    let newArray = [
      ...formik.values.qualificationDetails,
      qualificationDetail.values,
    ];
    formik.setFieldValue("qualificationDetails", newArray);
  };

  const onSAVE = () => {
    let updatedArray = [...formik.values.qualificationDetails];
    updatedArray[editData.index] = qualificationDetail.values;
    formik.setFieldValue("qualificationDetails", updatedArray);
  };

  const onLoading = async () => {
    if (!isLoading) {
      setIsLoading(true);
      let res1 = await getAssociations();
      if (!res1.isError) {
        setAssociations(res1.data.associations);
      }
      let res2 = await getDegreeTypes();
      if (!res2.isError) {
        setDegreeTypes(res2.data.degreeTypes);
      }
      setIsLoading(false);
      console.log("associations ", setAssociations);
      console.log("degreeTypes ", degreeTypes);
    }
  };

  useState(() => {
    onLoading();
  }, []);

  console.log(qualificationDetail.degreeType);

  return (
    <div className="container-fluid page-center-div-container">
      <div
        className="container-fluid vh-100"
        onClick={() => {
          console.log("clicked");
          setShowQualificationDetailsForm(false);
        }}
      ></div>
      <div className="page-center-div w-50 shadow rounded p-2">
        <div className="bg-dark-subtle">
          <span className="d-block fs-5 fw-bolder w-100 p-2">
            ADD Qualification Detail
          </span>
        </div>
        <div className="overflow-wrapper">
          <Input
            type="text"
            label="Institution Name"
            id="institutionName"
            value={qualificationDetail.values.institutionName}
            onChange={qualificationDetail.handleChange}
            onBlur={qualificationDetail.handleBlur}
            error={
              qualificationDetail.touched.institutionName &&
              qualificationDetail.errors.institutionName
                ? qualificationDetail.errors.institutionName
                : ""
            }
          />
          <SelectType3
            label={"Degree Type"}
            elementId={"degreeType"}
            optionsArray={degreeTypes}
            value={`${qualificationDetail.values.degreeType._id}:${qualificationDetail.values.degreeType.name}`}
            onChange={onSelectType3Change}
            onBlur={onSelectType3Blur}
            error={
              qualificationDetail.touched.degreeType?.name &&
              qualificationDetail.errors.degreeType?.name
                ? qualificationDetail.errors.degreeType?.name
                : ""
            }
          />
          <Input
            type="text"
            label="Degree Name"
            id="degreeName"
            value={qualificationDetail.values.degreeName}
            onChange={qualificationDetail.handleChange}
            onBlur={qualificationDetail.handleBlur}
            error={
              qualificationDetail.touched.degreeName &&
              qualificationDetail.errors.degreeName
                ? qualificationDetail.errors.degreeName
                : ""
            }
          />
          <Input
            type="date"
            label="Start Date"
            id="startDate"
            value={qualificationDetail.values.startDate}
            onChange={qualificationDetail.handleChange}
            onBlur={qualificationDetail.handleBlur}
            error={
              qualificationDetail.touched.startDate &&
              qualificationDetail.errors.startDate
                ? qualificationDetail.errors.startDate
                : ""
            }
          />
          <Input
            type="date"
            label='End Date (Kindly avoid specifying an end date if you prefer selecting the "Till date" option")'
            id="endDate"
            value={qualificationDetail.values.endDate}
            onChange={qualificationDetail.handleChange}
            onBlur={qualificationDetail.handleBlur}
            error={
              qualificationDetail.touched.endDate &&
              qualificationDetail.errors.endDate
                ? qualificationDetail.errors.endDate
                : ""
            }
          />
          <SelectType3
            label="Association"
            elementId={"association"}
            optionsArray={associations}
            value={`${qualificationDetail.values.association._id}:${qualificationDetail.values.association.name}`}
            onChange={onSelectType3Change}
            onBlur={onSelectType3Blur}
            error={
              qualificationDetail.touched.association?.name &&
              qualificationDetail.errors.association?.name
                ? qualificationDetail.errors.association?.name
                : ""
            }
          />
          <Input
            type="text"
            label="Speciality"
            id="speciality"
            value={qualificationDetail.values.speciality}
            onChange={qualificationDetail.handleChange}
            onBlur={qualificationDetail.handleBlur}
            error={
              qualificationDetail.touched.speciality &&
              qualificationDetail.errors.speciality
                ? qualificationDetail.errors.speciality
                : ""
            }
          />
          {/* <Input
            type="number"
            label="Score"
            id="score"
            value={qualificationDetail.values.score}
            onChange={qualificationDetail.handleChange}
            onBlur={qualificationDetail.handleBlur}
            error={
              qualificationDetail.touched.score &&
              qualificationDetail.errors.score
                ? qualificationDetail.errors.score
                : ""
            }
          /> */}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-primary"
            onClick={qualificationDetail.handleSubmit}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QualificationDetailFrom;
