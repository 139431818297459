export const gendersList = [
  { _id: "M", name: "Male" },
  { _id: "F", name: "Female" },
];

export const chargesTypeList = [
  { _id: "H", name: "Hourly" },
  { _id: "M", name: "Monthly" },
];

/**
 * Post Requirements
 */
