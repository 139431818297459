import React, { useState } from "react";
import ExperienceDetailsForm from "./ExperienceDetailsForm";
import ExperienceDetailListItem from "./ExperienceDetailListItem";
import Error from "../../common/Error";

const ExperienceDetails = ({ experienceDetails, formik }) => {
  const [showExperienceDetailsForm, setShowExperienceDetailsForm] =
    useState(false);

  const [formType, setFormType] = useState("");
  const [editData, setEditData] = useState({});

  const onEditClick = (data, index) => {
    console.log("Edit Click called");
    setEditData({ ...data, index });
    setFormType("Edit");
    setShowExperienceDetailsForm(true);
    if (!formik.touched.experienceDetails)
      formik.setFieldTouched("experienceDetails", true, true);
    let element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const onDeleteClick = (index) => {
    console.log("On Experience Delete Called");
    let filteredData = experienceDetails.filter((object, i) => i !== index);
    if (!formik.touched.experienceDetails)
      formik.setFieldTouched("experienceDetails", true, true);
    formik.setFieldValue("experienceDetails", filteredData, true);
  };
  return (
    <>
      <div id="experience-details" className="mt-3">
        <div className="d-flex justify-content-between align-items-center bg-dark-subtle">
          <div className="d-flex align-items-center">
            <span className="fs-5 fw-bolder w-100 p-2">Experience Details</span>
          </div>
          <div className="me-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShowExperienceDetailsForm(true);
                setFormType("Add");
                if (!formik.touched.experienceDetails)
                  formik.setFieldTouched("experienceDetails", true, true);
                let element = document.getElementById("root");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              ADD
            </button>
          </div>
        </div>
        <Error
          id="experienceDetailsError"
          error={
            formik.touched.experienceDetails && formik.errors.experienceDetails
              ? formik.errors.experienceDetails
              : ""
          }
        />
        {experienceDetails.map((data, index) => {
          return (
            <ExperienceDetailListItem
              key={`experienceDetail_${index}`}
              data={data}
              index={index}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      </div>
      {showExperienceDetailsForm && formType === "Add" && (
        <ExperienceDetailsForm
          setShowExperienceDetailsForm={setShowExperienceDetailsForm}
          btnText="ADD"
          formik={formik}
        />
      )}
      {showExperienceDetailsForm && formType === "Edit" && (
        <ExperienceDetailsForm
          setShowExperienceDetailsForm={setShowExperienceDetailsForm}
          btnText="SAVE"
          formik={formik}
          editData={editData}
        />
      )}
    </>
  );
};

export default ExperienceDetails;
