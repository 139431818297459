import React, { useEffect, useState } from "react";
import Error from "./Error";

// const databaseLevelsData = [
//   {
//     _id: 1,
//     heading: "Graduation Level",
//     items: [
//       { _id: 1, name: "English" },
//       { _id: 2, name: "Hindi" },
//       { _id: 3, name: "Math" },
//       { _id: 4, name: "Science" },
//       { _id: 5, name: "Java" },
//       { _id: 6, name: "PHP" },
//     ],
//   },
//   {
//     _id: 2,
//     heading: "School Level",
//     items: [
//       { _id: 7, name: "Grade 1" },
//       { _id: 8, name: "Grade 2" },
//       { _id: 9, name: "Grade 3" },
//       { _id: 10, name: "Grade 4" },
//       { _id: 11, name: "Grade 5" },
//       { _id: 12, name: "Grade 6" },
//     ],
//   },
// ];

// const databaseSubjectsandSkills = [
//   { _id: 1, name: "English" },
//   { _id: 2, name: "Hindi" },
//   { _id: 3, name: "Math" },
//   { _id: 4, name: "Science" },
//   { _id: 5, name: "Java" },
//   { _id: 6, name: "PHP" },
// ];

let selectedHeadingId = null;
let itemsWithHeadings = null;
let toLevelItemsWithHeadings = null;
const FromLevelAndToLevel = ({
  databaseLevelsData,
  setSelectedFromLevel,
  setSelectedToLevel,
  editFromLevel,
  editToLevel,
  fromLevelError,
  toLevelError,
}) => {
  // fromLevel states
  console.log("selectedHeadingId", selectedHeadingId);
  console.log("toLevelItemsWithHeadings", toLevelItemsWithHeadings);
  const [fromLevelSearchText, setFromLevelSearchText] = useState(
    editFromLevel ? editFromLevel : ""
  );
  const [showFromLevelListItems, setShowFromLevelListItems] = useState(false);
  const [fromLevelHeadingListItems, setFromLevelHeadingListItems] = useState(
    []
  );

  const [toLevelSearchText, setToLevelSearchText] = useState(
    editToLevel ? editToLevel : ""
  );
  const [showToLevelListItems, setShowToLevelListItems] = useState(false);
  const [toLevelHeadingListItems, setToLevelHeadingListItems] = useState([]);

  const fromLevelListItemClicked = (itemName, itemId, headingId) => {
    setSelectedFromLevel({ _id: itemId, name: itemName });
    setFromLevelSearchText(itemName);
    setShowFromLevelListItems(false);
    setShowToLevelListItems(false);
    if (selectedHeadingId !== headingId) {
      setToLevelSearchText("");
      selectedHeadingId = headingId;
      // update toLevelItemsWithHeadings
      toLevelItemsWithHeadings = filterHeadings(itemsWithHeadings);
      setToLevelHeadingListItems(toLevelItemsWithHeadings);
    }
  };
  const toLevelListItemClicked = (itemName, itemId, headingId) => {
    setSelectedToLevel({ _id: itemId, name: itemName });
    setToLevelSearchText(itemName);
    setShowToLevelListItems(false);
  };

  const dropdownFromLevelHeadingListItems = (headingListItems) => {
    return headingListItems.map((headingListItem) => {
      return (
        <div key={headingListItem._id}>
          <span className="d-block search-list-item-heading">
            {headingListItem.heading}
          </span>
          {headingListItem.items.map((item) => {
            return (
              <span
                key={item._id}
                className="d-block search-list-item"
                onClick={() => {
                  fromLevelListItemClicked(
                    item.name,
                    item._id,
                    headingListItem._id
                  );
                }}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      );
    });
  };
  const dropdownToLevelHeadingListItems = (headingListItems) => {
    return headingListItems.map((headingListItem) => {
      return (
        <div key={headingListItem._id}>
          <span className="d-block search-list-item-heading">
            {headingListItem.heading}
          </span>
          {headingListItem.items.map((item) => {
            return (
              <span
                key={item._id}
                className="d-block search-list-item"
                onClick={() => {
                  toLevelListItemClicked(
                    item.name,
                    item._id,
                    headingListItem._id
                  );
                }}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      );
    });
  };

  const filterListItems = (headingListItems, filterString) => {
    // deep cloning
    let filteredListItems = JSON.parse(JSON.stringify(headingListItems));
    filteredListItems = filteredListItems.filter((headingItemList) => {
      headingItemList.items = headingItemList.items.filter((item) => {
        return item.name.toLowerCase().includes(filterString);
      });
      if (headingItemList.items.length > 0) {
        return true;
      }
      return false;
    });
    return filteredListItems;
  };

  const onChangeFromLevel = (e) => {
    setFromLevelSearchText(e.target.value);
    if (e.target.value === "") {
      setShowFromLevelListItems(false);
      setShowToLevelListItems(false);
      toLevelItemsWithHeadings = null;
      setToLevelHeadingListItems(itemsWithHeadings);
    } else {
      let filteredListItems = filterListItems(
        itemsWithHeadings,
        e.target.value.toLowerCase()
      );
      if (filteredListItems.length > 0) {
        setShowFromLevelListItems(true);
        setFromLevelHeadingListItems(filteredListItems);
      } else {
        setShowFromLevelListItems(false);
      }
    }
  };

  const filterHeadings = (headingsList, filterHeadingId) => {
    // deep cloning
    let filteredHeading = JSON.parse(JSON.stringify(headingsList));
    filteredHeading = filteredHeading.filter((headingList) => {
      return headingList._id === selectedHeadingId;
    });
    return filteredHeading;
  };

  const onChangeToLevel = (e) => {
    setToLevelSearchText(e.target.value);
    if (e.target.value === "") {
      setShowToLevelListItems(false);
    } else {
      let filteredListItems = filterListItems(
        toLevelItemsWithHeadings,
        e.target.value.toLowerCase()
      );
      if (filteredListItems.length > 0) {
        setShowToLevelListItems(true);
        setToLevelHeadingListItems(filteredListItems);
      } else {
        setShowToLevelListItems(false);
      }
    }
  };

  useEffect(() => {
    // setDatabaseFromLevel(databaseLevelsData);
    itemsWithHeadings = databaseLevelsData;
    setFromLevelHeadingListItems(databaseLevelsData);
    setToLevelHeadingListItems(databaseLevelsData);
  }, []);
  return (
    <>
      <div className="mt-3">
        <label htmlFor="fromLevel" className="mb-1">
          From Level
        </label>
        <div className="d-flex w-100">
          <div className="input-group position-relative">
            <input
              type="text"
              id="fromLevel"
              className="form-control"
              style={{
                boxShadow: "none !important",
                border: "none !important",
              }}
              value={fromLevelSearchText}
              onChange={onChangeFromLevel}
            />
            {showFromLevelListItems ? (
              <>
                <div className="search-drop-down shadow">
                  {dropdownFromLevelHeadingListItems(fromLevelHeadingListItems)}
                </div>
              </>
            ) : (
              <></>
            )}
            <span
              className="input-group-text"
              onClick={() => {
                setFromLevelHeadingListItems(itemsWithHeadings);
                setShowToLevelListItems(false);
                setShowFromLevelListItems((prev) => {
                  return !prev;
                });
              }}
            >
              <svg
                fill="#000000"
                height="10px"
                width="10px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330 330"
                // xml:space="preserve"
              >
                <path
                  id="XMLID_225_"
                  d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                />
              </svg>
            </span>
          </div>
        </div>
        <Error id="fromLevel" error={fromLevelError} />
      </div>
      <div className="mt-3">
        <label htmlFor="toLevel" className="mb-1">
          To Level
        </label>
        <div className="d-flex w-100">
          <div className="input-group position-relative">
            <input
              type="text"
              id="toLevel"
              className="form-control"
              style={{
                boxShadow: "none !important",
                border: "none !important",
              }}
              value={toLevelSearchText}
              onChange={onChangeToLevel}
            />
            {showToLevelListItems ? (
              <>
                <div className="search-drop-down shadow">
                  {dropdownToLevelHeadingListItems(toLevelHeadingListItems)}
                </div>
              </>
            ) : (
              <></>
            )}
            <span
              className="input-group-text"
              onClick={() => {
                if (toLevelItemsWithHeadings) {
                  setToLevelHeadingListItems(toLevelItemsWithHeadings);
                } else {
                  setToLevelHeadingListItems(itemsWithHeadings);
                }
                setShowToLevelListItems((prev) => {
                  return !prev;
                });
                setShowFromLevelListItems(false);
              }}
            >
              <svg
                fill="#000000"
                height="10px"
                width="10px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 330 330"
                // xml:space="preserve"
              >
                <path
                  id="XMLID_225_"
                  d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                />
              </svg>
            </span>
          </div>
        </div>
        <Error id={"toLevel"} error={toLevelError} />
      </div>
    </>
  );
};

export default FromLevelAndToLevel;
