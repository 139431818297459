import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import "../styles/pages/Portal/style.css";
import LeftSidebar from "../components/common/Portal/LeftSidebar";
import PortalHeader from "../components/common/Portal/PortalHeader";

const Portal = () => {
  return (
    <div className="main">
      <LeftSidebar />
      <div className="main-content-division">
        <div className="grid">
          <PortalHeader />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Portal;
