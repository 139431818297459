import { Link, useNavigate } from "react-router-dom";
// import SidebarDropdownButton from "../../Home/SidebarDropdownButton";
import "../../styles/components/common/Header/style.css";
import AuthContext from "../../context/AuthContext";
import { useContext, useState } from "react";

const Header = ({ isSideBarOpen, setIsSideBarOpen }) => {
  const { authUserData, setAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div className="header">
      {/* desktop view */}
      <div className="desktop-header">
        <div className="container-fluid p-0">
          <div className="d-flex bg-light p-2 justify-content-between px-4">
            <div>
              <Link to="/">
                <img
                  src="/assets/logo.jpg"
                  width="128px"
                  alt="logo"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </Link>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="pe-2">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
              {authUserData ? (
                <>
                  <div className="d-flex">
                    {authUserData.entityType === "tutor" && (
                      <div className="pe-2">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            navigate("/student/requirement/search");
                          }}
                        >
                          Tutor Jobs
                        </button>
                      </div>
                    )}
                    {authUserData.entityType === "student" && (
                      <div className="pe-2">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            navigate("/tutor/search");
                          }}
                        >
                          Find Tutors
                        </button>
                      </div>
                    )}
                    <div
                      className="rounded-2"
                      style={{
                        padding: "6px 12px",
                        backgroundColor: "#6c757D",
                        color: "white",
                      }}
                    >
                      <span className="">
                        Credits: {authUserData[authUserData.entityType].credits}
                      </span>
                    </div>
                    <div className="dropdown px-2">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {authUserData[authUserData.entityType].name}
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          {/* 
                    Anchor tag reloads the page that's why we will use Link component
                    <a className="dropdown-item" href="/login">
                      As Student
                    </a> */}
                          <Link
                            className="dropdown-item"
                            to={`/portal/${authUserData.entityType}/dashboard`}
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`/portal/${authUserData.entityType}/profile/update`}
                          >
                            Update Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/"
                            onClick={() => {
                              setAuthUserData(undefined);
                              localStorage.clear();
                            }}
                          >
                            Sign Out
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="pe-2">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate("/student/requirement/search");
                      }}
                    >
                      Tutor Jobs
                    </button>
                  </div>
                  <div className="pe-2">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate("/tutor/search");
                      }}
                    >
                      Find Tutors
                    </button>
                  </div>
                  <div className="dropdown pe-2">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Login
                    </button>
                    {/* <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        {/* 
                    Anchor tag reloads the page that's why we will use Link component
                    <a className="dropdown-item" href="/login">
                      As Student
                    </a> 
                        <Link className="dropdown-item" to="/login/student">
                          As Student
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/login/tutor">
                          As Tutor
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Registration
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to="/signup/student">
                          As Student
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/signup/tutor">
                          As Tutor
                        </Link>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div className="mobile-header">
        <div className="container-fluid p-0">
          <div className="d-flex bg-light p-4 justify-content-between">
            <div>
              <div>
                <Link to="/">
                  <img
                    src="/assets/logo.jpg"
                    width="128px"
                    alt="logo"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn bg-white"
                type="button"
                onClick={() => {
                  setIsSideBarOpen(true);
                }}
              >
                <img src="/assets/mobile-menu.png" alt="menu-icon" />
              </button>
            </div>
          </div>
        </div>
        {isSideBarOpen ? (
          <div className="container-fluid p-0 z-2 position-absolute top-0 start-0 d-flex h-100">
            <div
              className="w-25"
              onClick={() => {
                setIsSideBarOpen(false);
              }}
            ></div>
            <div className="w-75 p-3 bg-white">
              <div
                className="m-2 d-flex justify-content-end"
                onClick={() => {
                  setIsSideBarOpen(false);
                }}
              >
                <img src="/assets/cross-icon-mobile.png" alt="menu-icon" />
              </div>
              <div>
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary w-100 mb-2"
                    onClick={() => {
                      navigate("/contact-us");
                      setIsSideBarOpen(false);
                    }}
                  >
                    Contact Us
                  </button>
                </div>

                {authUserData ? (
                  <>
                    <div>
                      {authUserData.entityType === "tutor" && (
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-secondary w-100 mb-2"
                            onClick={() => {
                              navigate("/student/requirement/search");
                              setIsSideBarOpen(false);
                            }}
                          >
                            Tutor Jobs
                          </button>
                        </div>
                      )}
                      {authUserData.entityType === "student" && (
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-secondary w-100 mb-2"
                            onClick={() => {
                              navigate("/tutor/search");
                              setIsSideBarOpen(false);
                            }}
                          >
                            Find Tutors
                          </button>
                        </div>
                      )}
                      <div
                        className="rounded-2 text-center mb-2"
                        style={{
                          padding: "6px 12px",
                          backgroundColor: "#6c757D",
                          color: "white",
                        }}
                      >
                        <span className="">
                          Credits:{" "}
                          {authUserData[authUserData.entityType].credits}
                        </span>
                      </div>
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary w-100 mb-2 dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {authUserData[authUserData.entityType].name}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end w-100">
                          <li>
                            {/* 
                    Anchor tag reloads the page that's why we will use Link component
                    <a className="dropdown-item" href="/login">
                      As Student
                    </a> */}
                            <Link
                              className="dropdown-item"
                              to={`/portal/${authUserData.entityType}/dashboard`}
                              onClick={() => {
                                setIsSideBarOpen(false);
                              }}
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/portal/${authUserData.entityType}/profile/update`}
                              onClick={() => {
                                setIsSideBarOpen(false);
                              }}
                            >
                              Update Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/"
                              onClick={() => {
                                setAuthUserData(undefined);
                                localStorage.clear();
                                setIsSideBarOpen(false);
                              }}
                            >
                              Sign Out
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary w-100 mb-2"
                        onClick={() => {
                          navigate("/student/requirement/search");
                          setIsSideBarOpen(false);
                        }}
                      >
                        Tutor Jobs
                      </button>
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-secondary w-100 mb-2"
                        onClick={() => {
                          navigate("/tutor/search");
                          setIsSideBarOpen(false);
                        }}
                      >
                        Find Tutors
                      </button>
                    </div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary w-100 mb-2"
                        type="button"
                        onClick={() => {
                          navigate("/login");
                          setIsSideBarOpen(false);
                        }}
                      >
                        Login
                      </button>
                      {/* <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        {/* 
                    Anchor tag reloads the page that's why we will use Link component
                    <a className="dropdown-item" href="/login">
                      As Student
                    </a> 
                        <Link className="dropdown-item" to="/login/student">
                          As Student
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/login/tutor">
                          As Tutor
                        </Link>
                      </li>
                    </ul> */}
                    </div>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary w-100 mb-2 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Registration
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end w-100">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/signup/student"
                            onClick={() => {
                              setIsSideBarOpen(false);
                            }}
                          >
                            As Student
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/signup/tutor"
                            onClick={() => {
                              setIsSideBarOpen(false);
                            }}
                          >
                            As Tutor
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Header;
