import React, { useContext, useEffect, useState } from "react";
import { SkillContext } from "../context/HomeSkillContext";
import TutorList from "../components/pages/SearchedTutors/TutorList";
import { getTutors } from "../apis/tutor.apis";
import SelectSkill from "../components/common/SelectSkill";

const SearchedTutors = () => {
  const { skills, skill, setSkill } = useContext(SkillContext);
  const [tutors, setTutors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const searchTutors = async () => {
    console.log("searchedTutors");
    let res;
    if (skill._id !== "#") res = await getTutors({ skill: skill._id });
    else res = await getTutors({});
    setTutors(res.data.tutors);
  };

  const handleSearchClick = async () => {
    setIsLoading(true);
    await searchTutors();
    setIsLoading(false);
  };

  useEffect(() => {
    if (skills.length > 0) {
      searchTutors();
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [skills]);

  return isLoading ? (
    <>Loading...</>
  ) : (
    <div className="w-75 ms-auto me-auto">
      <div className="d-flex justify-content-center m-4">
        {/* <SelectHome
          elementId="skill"
          label="Skill"
          defaultValue={skill._id}
          onChange={onSkillChange}
          optionsArray={skills}
          width="75%"
        /> */}
        <SelectSkill skills={skills} skill={skill} setSkill={setSkill} />
        <button className="btn btn-success my-2" onClick={handleSearchClick}>
          <span className="fw-semibold">Search</span>
        </button>
      </div>

      {tutors.length > 0 &&
        tutors.map((tutor) => {
          return <TutorList key={tutor._id} tutor={tutor} />;
        })}
    </div>
  );
};

export default SearchedTutors;
