import React, { useContext, useEffect, useState } from "react";
import { Country } from "country-state-city";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useFormik } from "formik";
import { updateSchema } from "../../validations/student";
import moment from "moment";
import ContentContainer from "../../components/common/Portal/ContentContainer";
import Input from "../../components/common/Input";

const StudentUpdateProfile = () => {
  const { authUserData, getAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const onUpdateClicked = async () => {
    let { name } = formik.values;

    let token = authUserData.token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    console.log("headers ", headers);
    console.log("token ", token);

    try {
      const { data } = await axios.put(
        "http://localhost:3000/v1/student",
        {
          name,
        },
        { headers }
      );
      console.log("data", data);
      await getAuthUserData(token, "student");
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: authUserData ? authUserData.student.name : "",
      mobile: authUserData ? authUserData.student.mobile : "",
      email: authUserData ? authUserData.student.email : "",
    },
    validationSchema: updateSchema,
    onSubmit: () => {
      onUpdateClicked().then((res) => {
        console.log(res);
        console.log("Profile Updated Successfully");
      });
    },
  });

  useEffect(() => {
    console.log("authUserData", authUserData);
    if (!authUserData) {
      navigate("/");
    }
  }, []);

  console.log("otuside use effect authUserData", authUserData);
  return authUserData ? (
    <ContentContainer>
      <div className="container-fluid mt-2 mb-2">
        <div className="d-flex p-2 flex-column">
          <div id="complete-profile" className="mt-2 mb-2">
            <div className="d-flex justify-content-between align-items-center rounded bg-info">
              <div className="d-flex align-items-center">
                <span className="fs-5 fw-bolder w-100 p-2">Update Profile</span>
              </div>
              <div className="me-3">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={formik.handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="basic-details" className="d-flex pe-2 ps-2 flex-column">
          <div className="bg-dark-subtle">
            <span className="d-block fs-5 fw-bolder w-100 p-2">
              BASIC DETAILS
            </span>
          </div>
          <div>
            <div className="d-flex gap-4">
              <div className="w-50 pe-2 ps-2">
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    type="text"
                    id="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : ""
                    }
                  />
                  <Input
                    type="text"
                    maxLength="10"
                    id="mobile"
                    label="Mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                    error={
                      formik.touched.mobile && formik.errors.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                  />
                  <Input
                    type="email"
                    id="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={true}
                    error={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ""
                    }
                  />
                </form>
              </div>
              {/* <div className="w-50">
                <div className="p-2">
                  <label htmlFor="formFile" className="form-label">
                    Default file input example
                  </label>
                  <input className="form-control" type="file" id="formFile" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </ContentContainer>
  ) : null;
};

export default StudentUpdateProfile;
