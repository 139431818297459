import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../styles/pages/Home/style.css";
import { Autoplay, Pagination } from "swiper/modules";
import ReviewCard from "../components/pages/Home/ReviewCard";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SkillContext } from "../context/HomeSkillContext";
import AuthContext from "../context/AuthContext";
import SelectSkill from "../components/common/SelectSkill";

const Home = () => {
  const navigate = useNavigate();
  const { authUserData } = useContext(AuthContext);
  const { skills, skill, setSkill } = useContext(SkillContext);

  return (
    <main>
      <div className="d-flex justify-content-center m-2">
        {`For buying credits and any feedback/queries, reach out at`}&nbsp;
        <b>findtutorhub@gmail.com</b>
      </div>
      {authUserData &&
      authUserData.entityType === "tutor" &&
      authUserData.tutor.isProfileCompleted === false ? (
        <div className="container-fluid" style={{ cursor: "pointer" }}>
          <div
            className="d-flex justify-content-center align-items-center my-2 mx-auto"
            onClick={() => {
              navigate("/tutor/complete-profile");
            }}
          >
            <div>Please complete your profile</div>
            <div className="ms-2">
              <button className="btn btn-danger" style={{ fontSize: "12px" }}>
                <span className="fw-semibold">Continue</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="container-fluid p-0 position-relative">
        <div className="hero-section">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="hero-section-left">I am looking for</div>
            {/* <SelectHome
              elementId="skill"
              label="Skill"
              defaultValue={skill._id}
              onChange={onSkillChange}
              optionsArray={skills}
              width="30%"
            /> */}
            <div className="d-flex align-items-center justify-content-center w-100">
              <SelectSkill skills={skills} skill={skill} setSkill={setSkill} />
              <button
                className="btn btn-success"
                onClick={() => {
                  navigate("/tutor/search");
                }}
              >
                <span className="fw-semibold hero-section-right-text">
                  Search
                </span>
              </button>
            </div>
          </div>
          <div>
            <img src="./assets/hero-section-image.png" width="100%" />
          </div>
        </div>

        <div className="reviews mx-auto p-4" style={{ width: "90%" }}>
          <Swiper
            slidesPerView={2}
            spaceBetween={20}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <ReviewCard
                rating="5"
                bodyText="FindTutorHub has been a game-changer for me! The personalized tutoring sessions have truly boosted my confidence and understanding in various subjects. The platform is user-friendly, and the tutors are incredibly knowledgeable and supportive. I highly recommend FindTutorHub to any student seeking academic success!"
                name="Simran"
                from="Jaipur"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard
                rating="5"
                bodyText="FindTutorHub exceeded my expectations! The tutors are top-notch, providing tailored support that helped me excel in my studies. The platform's convenience and flexibility make learning a breeze. I'm grateful for the valuable assistance and highly recommend FindTutorHub to all students!"
                name="Sagar"
                from="Mumbai"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard
                rating="4.5"
                bodyText="FindTutorHub has been a lifesaver for our family! The tutors are skilled professionals who have made a significant impact on our child's academic performance. The platform's easy-to-use interface and flexible scheduling options have made it a convenient choice for us. We're thrilled with the results and highly recommend FindTutorHub to other parents seeking quality tutoring services."
                name="Amit"
                from="Kota"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReviewCard
                rating="4.5"
                bodyText="FindTutorHub has truly been a blessing for our family! The tutors provided exceptional support, tailoring lessons to our child's needs and ensuring steady progress. The platform's seamless interface and flexible scheduling options made it easy to integrate into our busy lives. We're grateful for the positive impact it's had on our child's education and highly recommend FindTutorHub to other parents."
                name="Atul"
                from="Mumbai"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </main>
  );
};

export default Home;
