import React, { useState } from "react";
import "../../../styles/components/pages/CompleteProfile/TeachingDetailForm/style.css";
import SearchDropdown from "../../common/SearchDropdown";
import FromLevelAndToLevel from "../../common/FromLevelAndToLevel";
import { useFormik } from "formik";
import { teachingDetailSchema } from "../../../validations/tutor";
import { getLevels, getSkills } from "../../../apis/other.apis";

const TeachingDetailForm = ({
  setShowTeachingDetailsForm,
  setFieldValue,
  formik,
  btnText,
  editData,
}) => {
  console.log("editData", editData);
  const [isLoading, setIsLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [levels, setLevels] = useState([]);
  const teachingDetail = useFormik({
    // initialValues: {
    //   skill: editData ? editData.skill : { _id: null, name: "" },
    //   fromLevel: editData
    //     ? editData.fromLevel
    //     : {
    //         _id: null,
    //         name: "",
    //       },
    //   toLevel: editData
    //     ? editData.toLevel
    //     : {
    //         _id: null,
    //         name: "",
    //       },
    // },
    initialValues: editData
      ? editData
      : {
          skill: { _id: null, name: "" },
          fromLevel: {
            _id: null,
            name: "",
          },
          toLevel: {
            _id: null,
            name: "",
          },
        },
    validationSchema: teachingDetailSchema,
    onSubmit: () => {
      if (btnText === "ADD") onADD();
      else onSAVE();
      setShowTeachingDetailsForm(false);
    },
  });

  const convertLevels = (levels) => {
    let headingMap = new Map();
    const newArray = [];
    let headingIndexInNewArray;
    levels.map((level) => {
      headingIndexInNewArray = headingMap.get(level.heading._id);
      if (headingIndexInNewArray === undefined) {
        console.log("true,", true);
        headingIndexInNewArray = newArray.length;
        headingMap.set(level.heading._id, headingIndexInNewArray);
        newArray.push({
          _id: level.heading._id,
          heading: level.heading.name,
          items: [],
        });
      }
      newArray[headingIndexInNewArray].items.push({
        _id: level._id,
        name: level.name,
      });
      return "";
    });
    return newArray;
  };

  const onLoading = async () => {
    if (!isLoading) {
      setIsLoading(true);
      let skillsData = await getSkills();
      let levelsData = await getLevels();
      !skillsData.isError && setSkills(skillsData.data.skills);
      !levelsData.isError && setLevels(convertLevels(levelsData.data.levels));
      setIsLoading(false);
      console.log("skills ", skills);
      console.log("levels ", levels);
    }
  };

  const onADD = () => {
    let newArray = [...formik.values.teachingDetails, teachingDetail.values];
    setFieldValue("teachingDetails", newArray);
  };

  const onSAVE = () => {
    // setFieldValue((prev) => {
    //   prev[editData.index] = {
    //     skill: selectedSkill,
    //     fromLevel: selectedFromLevel,
    //     toLevel: selectedToLevel,
    //   };
    //   return prev;
    // });
    let updatedArray = [...formik.values.teachingDetails];
    console.log(updatedArray);
    updatedArray[editData.index] = teachingDetail.values;
    setFieldValue("teachingDetails", updatedArray);
  };

  const setSelectedSkill = (object) => {
    teachingDetail.setFieldValue("skill", object);
  };
  const setSelectedFromLevel = (object) => {
    teachingDetail.setFieldValue("fromLevel", object);
  };
  const setSelectedToLevel = (object) => {
    teachingDetail.setFieldValue("toLevel", object);
  };

  useState(() => {
    onLoading();
  }, []);

  return (
    <div className="container-fluid page-center-div-container">
      <div
        className="container-fluid vh-100"
        onClick={() => {
          console.log("clicked");
          setShowTeachingDetailsForm(false);
        }}
      ></div>
      <div className="page-center-div w-50 shadow rounded p-2">
        <div className="bg-dark-subtle">
          <span className="d-block fs-5 fw-bolder w-100 p-2">
            ADD Teaching Detail
          </span>
        </div>
        {skills.length > 0 && (
          <SearchDropdown
            databaseSubjectsandSkills={skills}
            setSelectedSkill={setSelectedSkill}
            editSkill={btnText === "SAVE" ? editData.skill.name : undefined}
            error={
              teachingDetail.touched.skill && teachingDetail.errors.skill
                ? teachingDetail.errors.skill.name
                : ""
            }
          />
        )}
        {levels.length > 0 && (
          <FromLevelAndToLevel
            databaseLevelsData={levels}
            setSelectedFromLevel={setSelectedFromLevel}
            setSelectedToLevel={setSelectedToLevel}
            editFromLevel={
              btnText === "SAVE" ? editData.fromLevel.name : undefined
            }
            editToLevel={btnText === "SAVE" ? editData.toLevel.name : undefined}
            fromLevelError={
              teachingDetail.touched.fromLevel &&
              teachingDetail.errors.fromLevel
                ? teachingDetail.errors.fromLevel.name
                : ""
            }
            toLevelError={
              teachingDetail.touched.toLevel && teachingDetail.errors.toLevel
                ? teachingDetail.errors.toLevel.name
                : ""
            }
          />
        )}
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-primary"
            onClick={teachingDetail.handleSubmit}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeachingDetailForm;
