import React, { useContext } from "react";
import "../../../styles/components/common/PortalHeader/style.css";
import AuthContext from "../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const PortalHeader = () => {
  const { authUserData, setAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div
      className="d-flex align-items-center justify-content-end"
      style={{ marginTop: "16px" }}
    >
      {authUserData.entityType === "tutor" && (
        <div className="pe-2">
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => {
              navigate("/student/requirement/search");
            }}
          >
            Find Jobs
          </button>
        </div>
      )}
      {authUserData.entityType === "student" && (
        <div className="pe-2">
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => {
              navigate("/tutor/search");
            }}
          >
            Find Tutors
          </button>
        </div>
      )}
      <div
        className="rounded-2"
        style={{
          padding: "7px 12px",
          backgroundColor: "#6c757D",
          color: "white",
        }}
      >
        <span className="">
          Credits: {authUserData[authUserData.entityType].credits}
        </span>
      </div>
      <div className="dropdown px-3">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {authUserData[authUserData.entityType].name}
        </button>
        <ul className="dropdown-menu dropdown-menu-end">
          <li>
            {/* 
                    Anchor tag reloads the page that's why we will use Link component
                    <a className="dropdown-item" href="/login">
                      As Student
                    </a> */}
            <Link
              className="dropdown-item"
              to={`/portal/${authUserData.entityType}/dashboard`}
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              className="dropdown-item"
              to={`/portal/${authUserData.entityType}/profile/update`}
            >
              Update Profile
            </Link>
          </li>
          <li>
            <Link
              className="dropdown-item"
              to="/"
              onClick={() => {
                setAuthUserData(undefined);
                localStorage.clear();
              }}
            >
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PortalHeader;
