import React from "react";
import Error from "./Error";

const Textarea = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  noOfCharacters = 10,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="p-2">
          <div className="form-group">
            <label htmlFor={id}>
              {`${label} `}
              {value.trim() !== "" &&
              value.trim().split(" ").length < noOfCharacters &&
              value.trim().split(" ").length >= 0
                ? `${
                    noOfCharacters - value.trim().split(" ").length
                  } words left`
                : ``}
            </label>
            <textarea
              className="form-control"
              id={id}
              value={value}
              rows="6"
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            ></textarea>
            <Error id={`${id}Error`} error={error} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Textarea;
