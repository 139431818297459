import React, { useContext, useEffect, useState } from "react";
import "../../styles/pages/Tutor/CompleteProfile/style.css";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { decreaseTutorCredits } from "../../apis/tutor.apis";
import LoginPage from "../Login";
import { getStudentRequirementById } from "../../apis/student.apis";

const ViewRequirement = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { studentRequirementId } = useParams();
  const { authUserData, getAuthUserData, openModal } = useContext(AuthContext);
  const [studentRequirement, setStudentRequirement] = useState();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onPhoneClicked = async () => {
    if (phone === "") {
      let params = {
        value: 5,
        reason: 1,
        studentRequirement: studentRequirementId,
      };
      const { isError } = await decreaseTutorCredits({}, params);
      if (!isError) {
        await getAuthUserData();
      }
    }
  };
  const onEmailClicked = async () => {
    if (email === "") {
      let params = {
        value: 5,
        reason: 2,
        studentRequirement: studentRequirementId,
      };
      const { isError } = await decreaseTutorCredits({}, params);
      if (!isError) {
        await getAuthUserData();
      }
    }
  };

  const getStudentRequirementDataById = async (studentRequirementId) => {
    console.log("authUserData", authUserData);

    const { data, isError } = await getStudentRequirementById({
      requirement: studentRequirementId,
    });
    if (!isError) {
      setStudentRequirement(data.studentRequirement);
      if (phone === "" && data.phone && data.phone.mobile) {
        setPhone(`(${data.phone.mobileCode})${data.phone.mobile}`);
      }
      if (email === "" && data.email) {
        setEmail(data.email);
      }
    }
  };

  useEffect(() => {
    console.log("studentRequirementId ", studentRequirementId);
    if (!studentRequirementId) {
      navigate("/", { replace: true });
    } else {
      console.log("authUserData", authUserData);
      console.log("executed", studentRequirementId);
      if (authUserData && studentRequirementId) {
        getStudentRequirementDataById(studentRequirementId);
      }
    }
  }, [authUserData]);

  useEffect(() => {
    // window.scrollTo(0, 0);

    setIsLoading(false);
  }, []);

  return isLoading ? (
    "Loading...."
  ) : authUserData && studentRequirement ? (
    <div className="container-fluid mt-2 mb-2">
      <div
        className="d-flex p-2 flex-column mx-auto flex-column"
        style={{ width: "80%" }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div>
            <h2 className="d-inline">{`${studentRequirement.title} `}</h2>
          </div>
          <div
            id="info"
            className="fw-semibold text-primary"
          >{`${studentRequirement.helpFor?.name} for ${studentRequirement.level?.name}`}</div>
        </div>
        <div className="d-flex justify-content-center">
          <div id="content" className="d-flex " style={{ width: "70%" }}>
            <ul className="list-group list-group-flush pe-4 pt-2 w-100">
              <li className="list-group-item pt-0 ps-0">
                <div id="paragraph" className="mb-2">
                  {studentRequirement.description.split("\n").map((string) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(string + "<br>"),
                        }}
                      />
                    );
                  })}
                </div>
              </li>
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Subjects/Skills</h3>
                </div>
                {`${studentRequirement.skills
                  .map((skill) => skill.name)
                  .join(",")}`}
              </li>
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Can communicate in</h3>
                </div>
                {`${studentRequirement.languages
                  .map((language) => language.name)
                  .join(",")}`}
              </li>
              {/* <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Experience</h3>
                  <ul className="list-unstyled">
                    {studentRequirement.experiences.map((experience) => {
                      return (
                        <li key={experience._id}>
                          <h5>
                            {`${experience.organization} `}
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#777777",
                                fontWeight: "bold",
                              }}
                            >
                              {`${experience.designation}`}{" "}
                            </span>
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#777777",
                              }}
                            >{`(${moment(experience.startDate).format(
                              "MMM,YYYY"
                            )} - ${moment(experience.endDate).format(
                              "MMM,YYYY"
                            )})`}</span>
                          </h5>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Education</h3>
                  <ul className="list-unstyled">
                    {studentRequirement.qualifications.map((qualification) => {
                      return (
                        <li>
                          <h5>
                            {`${qualification.degreeName} `}
                            <span
                              style={{ fontSize: "14px", color: "#777777" }}
                            >
                              {`(${moment(qualification.startDate).format(
                                "MMM, YYYY"
                              )} - ${moment(qualification.endDate).format(
                                "MMM, YYYY"
                              )}) from`}
                            </span>{" "}
                            <span
                              style={{ fontSize: "14px", color: "#777777" }}
                            >
                              {`${qualification.institutionName}`}
                            </span>
                          </h5>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li> */}
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Budget</h3>
                  <ul className="list-unstyled">
                    <li>
                      <h5>
                        {`${studentRequirement.budgetCurrency} ${studentRequirement.budget} ${studentRequirement.budgetFrequency.name}`}
                      </h5>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div id="right-sidebar" style={{ width: "30%" }}>
            <div
              className="p-2 rounded mt-2 w-100"
              style={{ background: "#6C757D" }}
            >
              <div className="d-flex flex-column align-items-center">
                <div>
                  <button
                    className="me-2"
                    onClick={() => {
                      if (email === "") {
                        openModal({
                          title: "Alert",
                          body: "5 credits will be deducted to view student email. Are you sure to continue?",
                          btnLabel: "Continue",
                          btnHandler: onEmailClicked,
                        });
                      }
                    }}
                  >
                    {email ? `${email}` : "Email"}
                  </button>
                  <button
                    onClick={() => {
                      if (phone === "") {
                        openModal({
                          title: "Alert",
                          body: "5 credits will be deducted to view student phone number. Are you sure to continue?",
                          btnLabel: "Continue",
                          btnHandler: onPhoneClicked,
                        });
                      }
                    }}
                  >
                    {phone ? `${phone}` : "Phone"}
                  </button>
                </div>
                {/* <div className="mt-2">
                  <button className="me-2">Pay</button>
                  <button>Review</button>
                </div> */}
              </div>
              <div className="bg-white m-2">
                <ul className="list-group">
                  {studentRequirement.meetingTypes[0]?.name ===
                  "At my place" ? (
                    <li className="list-group-item">{`${studentRequirement.city}, ${studentRequirement.state}, ${studentRequirement.country}`}</li>
                  ) : null}
                  <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Meeting type: </span>
                      <span>
                        {studentRequirement.meetingTypes
                          .map((meeting) => meeting.name)
                          .join(", ")}
                      </span>
                    </div>
                  </li>
                  {/* <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Heading: </span>
                      <span>Value</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Heading: </span>
                      <span>Value</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Heading: </span>
                      <span>Value</span>
                    </div>
                  </li>
                  <li className="list-group-item">Morbi leo risus</li>
                  <li className="list-group-item">Porta ac consectetur ac</li>
                  <li className="list-group-item">Vestibulum at eros</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoginPage isNavigate={false} defaultTab={"tutor"} isShowTabs={false} />
  );
};

export default ViewRequirement;
