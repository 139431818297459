import React from "react";

const PaymentToTutor = () => {
  return (
    <div className="d-flex justify-content-center m-4">
      <div className="p-4">
        <div className="d-flex justify-content-center mb-2">
          <span className="fw-bold fs-3">Payment To Tutor</span>
        </div>
        <p>
          FindTutorHub.com plays a vital role in connecting tutors and students.
          However, we do not intervene in tuition or assignment fees. We
          strongly advise both tutors and students to exercise due diligence
          when exchanging personal information or engaging in contractual
          agreements, including payments.
        </p>

        <p>
          <strong>For Tutor:</strong>
        </p>
        <p>
          You can request payment through FindTutorHub for your services. Many
          students hesitate to pay upfront due to uncertainty about the service
          quality. However, by ensuring their payment is safeguarded against
          subpar tutors, they'll find it risk-free to proceed. Even if a student
          becomes unresponsive, you'll still receive payment within a month, a
          common issue faced by tutor’s post-service delivery.
        </p>

        <p>
          For projects or assignments, always negotiate upfront payment before
          commencement. If a student is hesitant, consider accepting a partial
          amount initially. Provide evidence of partial completion and request
          additional payment to finalize the task.
        </p>

        <p>
          When conducting online tutoring, offer a trial session or
          demonstration to gauge compatibility. Subsequently, request payment on
          a per-lesson basis to maintain trust and commitment.
        </p>

        <p>
          <strong>For Student:</strong>
        </p>
        <p>
          Make payments through FindTutorHub for added security. Your funds
          remain with us until you're satisfied with the service provided, and
          we facilitate refunds if the tutor fails to meet expectations as per
          agreed terms and conditions finalized between Student and Tutor.
        </p>

        <p>
          When meeting in person, financial fraud risks decrease, but prioritize
          personal safety. If unsure, defer to parental advice or common sense.
          Always meet in public places for initial meetings.
        </p>

        <p>
          <strong>
            If a tutor requests advance payment outside of FindTutorHub, your
            response could be:
          </strong>
        </p>
        <blockquote>
          <p>
            "I'm hesitant about making upfront payments without any security
            measures in place. To ensure a secure transaction, I'd rather
            deposit the funds into your FindTutorHub account and release them
            once the work is completed to my satisfaction."
          </p>
        </blockquote>
      </div>
    </div>
  );
};

export default PaymentToTutor;
