import React, { useState } from "react";
import Error from "./common/Error";

const TwoRadioButtons = ({ qLabel, name, formik }) => {
  return (
    <div className="row">
      <div className="col-6">
        <div className="p-2">
          {`${qLabel}`}
          <Error
            id={name}
            error={
              formik.touched.qna !== undefined &&
              formik.touched.qna[name] !== undefined &&
              formik.errors.qna !== undefined &&
              formik.errors.qna[name] !== undefined
                ? formik.errors.qna[name]
                : ""
            }
          />
        </div>
      </div>
      <div className="col-6">
        <div className="p-2">
          <input
            className="form-check-input"
            type="radio"
            name={`qna.${name}`}
            id={`${name}Y`}
            value="Y"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.qna[name] === "Y"}
          />
          <label
            className="form-check-label"
            htmlFor={`${name}Y`}
            className="ms-2 me-4"
          >
            Yes
          </label>
          <input
            className="form-check-input"
            type="radio"
            name={`qna.${name}`}
            id={`${name}N`}
            value="N"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.qna[name] === "N"}
          />
          <label
            className="form-check-label"
            htmlFor={`${name}N`}
            className="ms-2"
          >
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default TwoRadioButtons;
