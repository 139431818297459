import { getRequest } from "./requests";

const SKILL_URL = "/v1/admin/skill/search";
const ASSOCIATIONS_URL = "/v1/admin/association/search";
const DEGREE_TYPES_URL = "/v1/admin/degreeType/search";
const MEET_TYPES_URL = "/v1/admin/meetType/search";
const HELP_TYPES_URL = "/v1/admin/helpType/search";
const BUDGET_FREQUENCIES_URL = "/v1/admin/budgetFrequency/search";
const LANGUAGES_URL = "/v1/admin/language/search";
const LEVELS_URL = "/v1/admin/level/search?sortBy=heading&sortOrder=asc";

export const getSkills = async (params) => {
  return await getRequest(SKILL_URL, params);
};

export const getAssociations = async (params) => {
  return await getRequest(ASSOCIATIONS_URL, params);
};

export const getDegreeTypes = async (params) => {
  return await getRequest(DEGREE_TYPES_URL, params);
};

export const getMeetTypes = async (params) => {
  return await getRequest(MEET_TYPES_URL, params);
};

export const getHelpTypes = async (params) => {
  return await getRequest(HELP_TYPES_URL, params);
};

export const getBudgetFrequencies = async (params) => {
  return await getRequest(BUDGET_FREQUENCIES_URL, params);
};

export const getLanguages = async (params) => {
  return await getRequest(LANGUAGES_URL, params);
};

export const getLevels = async (params) => {
  return await getRequest(LEVELS_URL, params);
};
