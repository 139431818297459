import React from "react";

const TeachingDetailListItem = ({
  data,
  index,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <div className="teaching-detail-list-item d-flex justify-content-between align-items-center">
      <span>{`${data.skill.name} (${data.fromLevel.name} - ${data.toLevel.name})`}</span>
      <div>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm me-2"
          onClick={() => {
            console.log("Edit clicked");
            onEditClick(data, index);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-sm me-2"
          onClick={() => {
            console.log("On Delete Clicked");
            onDeleteClick(index);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default TeachingDetailListItem;
