import React from "react";

const ContentContainer = ({ children }) => {
  return (
    <div
      className="rounded"
      style={{
        margin: "16px",
        backgroundColor: "white",
        padding: "8px",
        overflowY: "auto",
      }}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
