import React, { useContext, useEffect, useState } from "react";
import ContentContainer from "../../components/common/Portal/ContentContainer";
import Input from "../../components/common/Input";
import { useFormik } from "formik";
import Textarea from "../../components/common/Textarea";
import SelectMultiple from "../../components/common/SelectMultiple";
import { Country } from "country-state-city";
import SelectCurrency from "../../components/common/SelectCurrency";
import { requirementValidationSchema } from "../../validations/student";
import SelectType3 from "../../components/common/SelectType3";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  getBudgetFrequencies,
  getHelpTypes,
  getLanguages,
  getLevels,
  getMeetTypes,
  getSkills,
} from "../../apis/other.apis";
import {
  createStudentRequirement,
  updateStudentRequirement,
} from "../../apis/student.apis";
import Error from "../../components/common/Error";

const PostRequirement = ({
  type,
  setType,
  editData,
  setEditData,
  fetchAllUserRequirements,
}) => {
  const { authUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [checkBoxes, setCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [skills, setSkills] = useState([]);
  const [levels, setLevels] = useState([]);
  const [countries, setCountries] = useState([]);
  const [helpTypes, setHelpTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [meetTypes, setMeetTypes] = useState([]);
  const [budgetFrequencies, setBudgetFrequencies] = useState([]);

  const onLoading = async () => {
    let skillsData = await getSkills();
    let levelsData = await getLevels();
    let helpTypesData = await getHelpTypes();
    let languagesData = await getLanguages();
    let meetTypesData = await getMeetTypes();
    let budgetFrequenciesData = await getBudgetFrequencies();
    !skillsData.isError && setSkills(skillsData.data.skills);
    !levelsData.isError && setLevels(levelsData.data.levels);
    !helpTypesData.isError && setHelpTypes(helpTypesData.data.helpTypes);
    !languagesData.isError && setLanguages(languagesData.data.languages);
    !meetTypesData.isError && setMeetTypes(meetTypesData.data.meetTypes);
    !budgetFrequenciesData.isError &&
      setBudgetFrequencies(budgetFrequenciesData.data.budgetFrequencies);

    let countries = Country.getAllCountries();
    setCountries(countries);
    setIsLoading(false);
  };

  const onSubmitClicked = async (values, { resetForm }) => {
    let skillsArray = values.skills.map((skillObject) => skillObject._id);
    let languagesArray = values.languages.map(
      (languageObject) => languageObject._id
    );
    let meetingTypesArray = values.meetingTypes.map(
      (meetTypeObject) => meetTypeObject._id
    );
    let requirementObject = {
      helpFor: values.helpFor._id,
      meetingTypes: meetingTypesArray,
      title: values.title,
      description: values.description,
      skills: skillsArray,
      languages: languagesArray,
      level: values.level._id,
      budgetCurrency: values.budgetCurrency,
      budget: values.budget,
      budgetFrequency: values.budgetFrequency._id,
    };

    if (type === "Edit") {
      const { isError } = await updateStudentRequirement(requirementObject, {
        requirement: editData._id,
      });
      if (!isError) {
        await fetchAllUserRequirements();
        alert("Requirement updated successfully");
        let resetCheckboxesArray = meetTypes.map((item) => false);
        setCheckboxes(resetCheckboxesArray);
        setEditData(null);
        setType("Post");
      }
    } else {
      const { isError } = await createStudentRequirement(requirementObject);
      if (!isError) {
        alert("Requirement submitted successfully");
        let resetCheckboxesArray = meetTypes.map((item) => false);
        setCheckboxes(resetCheckboxesArray);
        resetForm();
      }
    }
  };

  const requirement = useFormik({
    initialValues:
      type === "Edit" || type === "View"
        ? editData
        : {
            helpFor: { _id: null, name: "#" },
            meetingTypes: [],
            title: "",
            description: "",
            skills: [],
            languages: [],
            level: { _id: null, name: "#" },
            budgetCurrency: "",
            budget: 0,
            budgetFrequency: { _id: null, name: "#" },
          },
    validationSchema: requirementValidationSchema,
    onSubmit: onSubmitClicked,
  });

  const onSelectType3Change = (e) => {
    let [_id, name] = e.target.value.split(":");
    if (name === "#") {
      requirement.setFieldValue(`${e.target.id}`, {
        _id: null,
        name,
      });
    } else {
      requirement.setFieldValue(`${e.target.id}`, {
        _id: Number(_id),
        name,
      });
    }
  };
  const onSelectType3Blur = (e) => {
    requirement.setFieldTouched(
      `${e.target.id}`,
      { _id: true, name: true },
      true
    );
  };

  const isMeetTypeExistInMeetingTypes = (meetingTypes, _meetTypeid) => {
    let isExist = false;
    for (let i = 0; i < meetingTypes.length; i++) {
      if (meetingTypes[i]._id === _meetTypeid) {
        isExist = true;
        break;
      }
    }
    return isExist;
  };

  const updateCheckboxes = (i) => {
    setCheckboxes((prev) => {
      let newArray = [...prev];
      newArray[i] = !newArray[i];
      return newArray;
    });
  };

  useEffect(() => {
    onLoading();
  }, []);

  useEffect(() => {
    if (type === "Edit" || type === "View") {
      let checkboxesArray = meetTypes.map(({ _id }) => {
        return isMeetTypeExistInMeetingTypes(
          requirement.values.meetingTypes,
          _id
        );
      });
      setCheckboxes(checkboxesArray);
    } else {
      let checkboxesArray = meetTypes.map((item) => false);
      setCheckboxes(checkboxesArray);
    }
  }, [meetTypes]);

  return isLoading ? null : (
    <ContentContainer>
      <div className="container-fluid mt-2 mb-2">
        <div className="d-flex p-2 flex-column">
          <div id="post-requirement" className="mt-2 mb-2">
            <div className="d-flex justify-content-between align-items-center rounded bg-info">
              <div className="d-flex align-items-center">
                <span className="fs-5 fw-bolder w-100 p-2">
                  {`${type}`} Requirement
                </span>
              </div>
              <div className="me-3">
                {type === "Edit" || type === "Post" ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={requirement.handleSubmit}
                  >
                    {type === "Edit" ? "Update" : "Submit"}
                  </button>
                ) : null}
                {type === "Edit" || type === "View" ? (
                  <button
                    type="button"
                    className="btn btn-dark ms-1"
                    onClick={() => {
                      setEditData(null);
                      setType("Post");
                    }}
                  >
                    Close
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                id={"title"}
                label={"Requirement Title/Heading"}
                value={requirement.values.title}
                type={"text"}
                onChange={requirement.handleChange}
                onBlur={requirement.handleBlur}
                error={
                  requirement.touched.title && requirement.errors.title
                    ? requirement.errors.title
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Textarea
                id={"description"}
                label={"Description (min. 10 words)"}
                value={requirement.values.description}
                onChange={requirement.handleChange}
                onBlur={requirement.handleBlur}
                error={
                  requirement.touched.description &&
                  requirement.errors.description
                    ? requirement.errors.description
                    : ""
                }
                disabled={type === "View" ? true : false}
                noOfCharacters={10}
              />
            </div>
          </div>
          <SelectMultiple
            formik={requirement}
            label={"Skills"}
            elementId={"skills"}
            optionsArray={skills}
            error={
              requirement.touched.skills && requirement.errors.skills
                ? requirement.errors.skills
                : ""
            }
            selectedData={requirement.values.skills}
            disabled={type === "View" ? true : false}
          />
          <SelectMultiple
            formik={requirement}
            label={"Communication language"}
            elementId={"languages"}
            optionsArray={languages}
            error={
              requirement.touched.languages && requirement.errors.languages
                ? requirement.errors.languages
                : ""
            }
            selectedData={requirement.values.languages}
            disabled={type === "View" ? true : false}
          />
          <div className="row">
            <div className="col-4">
              <SelectType3
                label="Level"
                optionsArray={levels}
                elementId={"level"}
                onChange={onSelectType3Change}
                onBlur={onSelectType3Blur}
                value={`${requirement.values.level._id}:${requirement.values.level.name}`}
                error={
                  requirement.touched.level && requirement.errors.level
                    ? requirement.errors.level.name
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div>
            <div className="col-4">
              <SelectType3
                label="Help for"
                optionsArray={helpTypes}
                elementId={"helpFor"}
                onChange={onSelectType3Change}
                onBlur={onSelectType3Blur}
                value={`${requirement.values.helpFor._id}:${requirement.values.helpFor.name}`}
                error={
                  requirement.touched.helpFor && requirement.errors.helpFor
                    ? requirement.errors.helpFor.name
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div>
            {/* <div className="col-4">
              <SelectType3
                label="Meeting Type"
                optionsArray={meetTypes}
                elementId={"meetType"}
                onChange={onSelectType3Change}
                onBlur={onSelectType3Blur}
                value={`${requirement.values.meetType._id}:${requirement.values.meetType.name}`}
                error={
                  requirement.touched.meetType && requirement.errors.meetType
                    ? requirement.errors.meetType.name
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-4">
              <Input
                label="Budget"
                type={"number"}
                id={"budget"}
                onChange={requirement.handleChange}
                onBlur={requirement.handleBlur}
                value={requirement.values.budget}
                error={
                  requirement.touched.budget && requirement.errors.budget
                    ? requirement.errors.budget
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div>
            <div className="col-4">
              <SelectCurrency
                label={"Budget Currency"}
                elementId="budgetCurrency"
                value={requirement.values.budgetCurrency}
                optionsArray={countries}
                onChange={requirement.handleChange}
                onBlur={requirement.handleBlur}
                error={
                  requirement.touched.budgetCurrency &&
                  requirement.errors.budgetCurrency
                    ? requirement.errors.budgetCurrency
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div>
            <div className="col-4">
              <SelectType3
                label="Budget Frequency"
                optionsArray={budgetFrequencies}
                type="number"
                elementId={"budgetFrequency"}
                onChange={onSelectType3Change}
                onBlur={onSelectType3Blur}
                value={`${requirement.values.budgetFrequency._id}:${requirement.values.budgetFrequency.name}`}
                error={
                  requirement.touched.budgetFrequency &&
                  requirement.errors.budgetFrequency
                    ? requirement.errors.budgetFrequency.name
                    : ""
                }
                disabled={type === "View" ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="p-2">
                <label htmlFor="meetingTypes">Meeting Type</label>
                <div id="meetingTypes" className="d-flex flex-column w-100">
                  {meetTypes.map((meetType, i) => {
                    return (
                      <div key={`meetType_${meetType._id}`}>
                        <input
                          className="me-2"
                          type="checkbox"
                          name="meetingType"
                          id={`meetingType_${meetType._id}`}
                          value={`${meetType._id}_${meetType.name}`}
                          // defaultChecked={isMeetTypeChecked(
                          //   requirement.values.meetingTypes,
                          //   meetType._id
                          // )}
                          disabled={type === "View" ? true : false}
                          checked={checkBoxes[i]}
                          // below code not work to set the initial state of checkbox
                          // checked={isMeetTypeChecked(
                          //   requirement.values.meetTypes,
                          //   meetType._id
                          // )}
                          onChange={(e) => {
                            console.log("change ", e.target.value);
                            console.log("checked", e.target.checked);
                            updateCheckboxes(i);
                            requirement.setFieldTouched(
                              "meetingTypes",
                              true,
                              true
                            );
                            let newArray = [...requirement.values.meetingTypes];
                            if (e.target.checked) {
                              let [_id, name] = e.target.value.split("_");

                              requirement.setFieldValue("meetingTypes", [
                                ...newArray,
                                { _id: Number(_id), name },
                              ]);
                            } else {
                              let remove_id = Number(
                                e.target.value.split("_")[0]
                              );
                              let filteredArray = newArray.filter(
                                ({ _id, name }) => _id !== remove_id
                              );
                              requirement.setFieldValue(
                                "meetingTypes",
                                filteredArray
                              );
                            }
                          }}
                          // checked={(e) => {
                          //   console.log("checkedd e", e);
                          //   // let newArray = [...requirement.values.meetTypes];
                          //   // if (e.target.checked) {
                          //   //   let [_id, name] = e.target.value.split("_");
                          //   //   requirement.setFieldValue("meetTypes", [
                          //   //     ...newArray,
                          //   //     { _id, name },
                          //   //   ]);
                          //   // } else {
                          //   //   let remove_id = e.target.value.split("_")[0];
                          //   //   let filteredArray = newArray.filter(
                          //   //     ({ _id, name }) => _id !== remove_id
                          //   //   );
                          //   //   requirement.setFieldValue(
                          //   //     "meetTypes",
                          //   //     filteredArray
                          //   //   );
                          //   // }

                          //   return isMeetTypeChecked(
                          //     requirement.values.meetTypes,
                          //     meetType._id
                          //   );
                          // }}
                        />
                        <label
                          htmlFor={`meetingType_${meetType._id}`}
                        >{`${meetType.name}`}</label>
                      </div>
                    );
                  })}
                </div>
                <Error
                  id={"meetingTypes"}
                  error={
                    requirement.touched.meetingTypes &&
                    requirement.errors.meetingTypes
                      ? requirement.errors.meetingTypes
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-center">
              {type === "Edit" || type === "Post" ? (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={requirement.handleSubmit}
                >
                  {type === "Edit" ? "Update" : "Submit"}
                </button>
              ) : null}
              {type === "Edit" || type === "View" ? (
                <button
                  type="button"
                  className="btn btn-dark ms-1"
                  onClick={() => {
                    setEditData(null);
                    setType("Post");
                  }}
                >
                  Close
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default PostRequirement;
