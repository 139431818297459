import React, { useContext } from "react";
import ContentContainer from "../../components/common/Portal/ContentContainer";
import AuthContext from "../../context/AuthContext";

const Dashboard = () => {
  const { authUserData } = useContext(AuthContext);
  return (
    <ContentContainer>
      <div
        className="rounded"
        style={{
          margin: "8px",
          backgroundColor: "white",
          padding: "8px",
          fontSize: "18px",
        }}
      >
        {`Welcome ${authUserData.tutor.name}`}
      </div>
    </ContentContainer>
  );
};

export default Dashboard;
