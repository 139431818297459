import * as Yup from "yup";
import moment from "moment";

const tutorDetailsSchema = Yup.object().shape({
  name: Yup.string().required(),
  gender: Yup.string().required(),
  dob: Yup.date()
    .required()
    .test("dob", "Age must be above 18", function (value) {
      return moment(value).isBefore(
        moment().subtract(18, "y").format("YYYY-MM-DD")
      );
    }),
  email: Yup.string()
    .email("Please enter correct email id")
    .required("Please provide email id"),
  mobileIsoCode: Yup.string().required("Please select mobile phone code"),
  mobileCode: Yup.string().required(),
  mobile: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("mobile", "Please provide correct mobile number", function (value) {
      let words = value.trim().length;
      return words === 10;
    }),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf(
      [Yup.ref("password")],
      "Password & Confirm Password does not matched"
    ),
  isChecked: Yup.boolean().isTrue("Accept Terms & Conditions must be checked"),
});

export const completeProfileSchema = Yup.object().shape({
  basicDetails: Yup.object().shape({
    cCode: Yup.string().required().notOneOf(["#"]),
    country: Yup.string().required().notOneOf(["#"], "Select Country"),
    sCode: Yup.string().required().notOneOf(["#"]),
    state: Yup.string().required().notOneOf(["#"], "Select State"),
    city: Yup.string().required().notOneOf(["#"], "Select City"),
    pincode: Yup.string().required("Should not be empty"),
    briefDescription: Yup.string()
      .required("Should not be empty")
      .max(70, "Numbers of characters exceeds 70"),
    introAboutYourself: Yup.string()
      .required("Should not be empty")
      .test(
        "words",
        "Must be at least 50 words",
        (val) => val.trim().split(" ").length > 50
      ),
  }),
  chargesDetails: Yup.object().shape({
    currency: Yup.string().required().notOneOf(["#"], "Select Currency"),
    chargesType: Yup.string().required().notOneOf(["#"], "Select Charges Type"),
    minFee: Yup.number().required().min(0),
    maxFee: Yup.number().required().min(0),
    feeVaryDetails: Yup.string(),
  }),
  teachingDetails: Yup.array().min(
    1,
    "Please provide at least one teaching detail"
  ),
  qualificationDetails: Yup.array().min(
    1,
    "Please provide at least one qualification detail"
  ),
  experienceDetails: Yup.array(),
  qna: Yup.object().shape({
    // willing to travel to student
    isWTTTS: Yup.string().required("Please select either Yes or No"),
    // how far can you travel
    travelDistance: Yup.number().required("Please provide travel distance"),
    // is available for online teaching
    isAFOT: Yup.string().required("Please select either Yes or No"),
    // do you have a digital pen
    dYHADP: Yup.string().required("Please select either Yes or No"),
    // do you help with homework and assignments
    dYHWHAA: Yup.string().required("Please select either Yes or No"),
    // are you currently a full time teacher employed by a School/College
    aYCAFTTEBASC: Yup.string().required("Please select either Yes or No"),
    // opportunities you are interested in
    oYAII: Yup.string(),
  }),
});

export const teachingDetailSchema = Yup.object().shape({
  skill: Yup.object().shape({
    _id: Yup.number().required(),
    name: Yup.string().required("Select Skill"),
  }),
  fromLevel: Yup.object().shape({
    _id: Yup.number().required(),
    name: Yup.string().required("Select From Level"),
  }),
  toLevel: Yup.object().shape({
    _id: Yup.number().required(),
    name: Yup.string().required("Select To Level"),
  }),
});

export const qualificationDetailsSchema = Yup.object().shape({
  institutionName: Yup.string().required("Please provide institution name"),
  degreeType: Yup.object().shape({
    _id: Yup.number().required().notOneOf(["#"], "Select Charges Type"),
    name: Yup.string().required().notOneOf(["#"], "Select Degree Type"),
  }),
  degreeName: Yup.string().required("Please provide degree name"),
  // startDate: Yup.string().required("Please provide start date"),
  // endDate: Yup.string().required("Please provide end date"),
  startDate: Yup.string(),
  endDate: Yup.string(),
  association: Yup.object().shape({
    _id: Yup.number().required().notOneOf(["#"], "Select Charges Type"),
    name: Yup.string().required().notOneOf(["#"], "Select Association"),
  }),
  // speciality: Yup.string().required("Please provide speciality"),
  speciality: Yup.string(),
  score: Yup.number().min(0),
});

export const experienceDetailSchema = Yup.object().shape({
  organization: Yup.string().required("Please provide organization"),
  designation: Yup.string().required("Please provide designation"),
  // startDate: Yup.string().required("Please provide start date"),
  // endDate: Yup.string().required("Please provide end date"),
  startDate: Yup.string(),
  endDate: Yup.string(),
  association: Yup.object().shape({
    _id: Yup.number().required().notOneOf(["#"], "Select Charges Type"),
    name: Yup.string().required().notOneOf(["#"], "Select Association"),
  }),
});

export { tutorDetailsSchema };
