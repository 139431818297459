import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ContentContainer from "../../components/common/Portal/ContentContainer";

const CheckTutor = () => {
  const navigate = useNavigate();
  const { authUserData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  let render = <></>;
  if (!authUserData.tutor.isProfileCompleted) {
    render = (
      <ContentContainer>
        Your Profile is incomplete.{" "}
        <Link to="/tutor/complete-profile">Click Here</Link> to complete your
        profile
      </ContentContainer>
    );
  } else if (authUserData.tutor.status === 0) {
    render = (
      <ContentContainer>"Your application is under process"</ContentContainer>
    );
  } else {
    render = <Outlet />;
  }

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return isLoading ? "Loading" : <>{render}</>;
};

export default CheckTutor;
