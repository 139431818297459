import axios from "axios";
import toast from "react-hot-toast";

const API = (isFormData) => {
  const token = localStorage.getItem("token");
  console.log("baseURL", process.env.REACT_APP_BASE_URL);
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  if (isFormData) {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  } else {
    api.defaults.headers.common["Content-Type"] = "application/json";
  }

  if (token) {
    api.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    api.defaults.headers.common["Authorization"] = null;
  }

  return api;
};

export const getRequest = async (url, params) => {
  try {
    const { data } = await API(false).get(`${url}`, { params });
    return { data, isError: false };
  } catch (error) {
    console.log(error);
    // showToast(`${error.response.data.msg}`, "error");
    toast.error(error.response.data.message);
    return { isError: true, error };
  }
};

export const postRequest = async (url, body, params) => {
  try {
    const { data } = await API(false).post(`${url}`, body, { params });
    return { data, isError: false };
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
    return { isError: true, error };
  }
};

export const putRequest = async (url, body, params) => {
  try {
    const { data } = await API(false).put(`${url}`, body, { params });
    return { data, isError: false };
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
    return { isError: true, error };
  }
};
