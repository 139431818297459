import React, { useState } from "react";

const Item = ({ name, _id, onClickItem }) => {
  return (
    <div
      className="d-inline m-1"
      style={{
        border: "1px solid #f2f2f9",
        borderRadius: "4px",
        padding: "3px 10px",
        position: "relative",
      }}
      onClick={(e) => {
        console.log("item clicked", _id, name);
        onClickItem(_id);
      }}
    >
      <span>{name}</span>
      <div
        id={"cross-icon"}
        style={{ position: "absolute", top: "-14px", right: "-5px" }}
      >
        <img
          src="/svgs/closeIcon.svg"
          alt="remove"
          width={"12"}
          height={"12"}
        />
      </div>
    </div>
  );
};

const SelectMultiple = ({
  formik,
  elementId,
  label,
  optionsArray,
  error,
  selectedData,
  disabled,
}) => {
  const [selectedItem, setSelectedItem] = useState({ _id: "#", name: "#" });

  const deleteSelectedItem = (itemId) => {
    let newArray = [...formik.values[elementId]];
    let filteredArray = newArray.filter((object) => object._id !== itemId);
    formik.setFieldValue(elementId, filteredArray);
  };

  return (
    <div className="row">
      {disabled ? null : (
        <div className="col-6">
          <div className="p-2">
            <label htmlFor={"selectItem"}>{label}</label>
            <div className="d-flex">
              <select
                id={"selectItem"}
                value={`${selectedItem._id}_${selectedItem.name}`}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  let [_id, name] = e.target.value.split("_");
                  setSelectedItem({ _id, name });
                  formik.setFieldValue(elementId, formik.values[elementId]);
                }}
                onBlur={() => {
                  formik.setFieldTouched(elementId, true, true);
                }}
                disabled={disabled}
              >
                <option key={`${"selectItem"}_#`} value="#_#">
                  {`--Select ${label}--`}
                </option>
                {optionsArray.map(({ _id, name }) => {
                  return (
                    <option
                      key={`${"selectItem"}_${_id}`}
                      value={`${_id}_${name}`}
                    >
                      {name}
                    </option>
                  );
                })}
              </select>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={() => {
                  if (selectedItem !== "#") {
                    let newArray = [...formik.values[elementId]];
                    let isElementFound = newArray.find(
                      (object) => object._id === selectedItem._id
                    );
                    if (!isElementFound) {
                      newArray.push(selectedItem);
                      formik.setFieldValue(elementId, newArray);
                    }
                  }
                }}
              >
                ADD
              </button>
            </div>
          </div>
          <div id={`${elementId}Error`}>
            <span className="error">{error}</span>
          </div>
        </div>
      )}
      <div className="col-6">
        <div className="p-2">
          <label htmlFor={elementId}>{`Selected ${label}`}</label>
          <div className="d-flex flex-wrap">
            {selectedData.map((object) => {
              return (
                <Item
                  key={object._id}
                  name={object.name}
                  _id={object._id}
                  onClickItem={deleteSelectedItem}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectMultiple;
