import React, { useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import { Outlet } from "react-router-dom";

const Simple = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  return (
    <>
      <Header isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
      <Outlet />
      <Footer />
    </>
  );
};

export default Simple;
