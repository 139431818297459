import { Rating } from "@mui/material";
import "../../../styles/components/pages/Home/ReviewCard/style.css";

const ReviewCard = ({ rating, bodyText, name, from }) => {
  return (
    <div className="container-fluid bg-white p-4 mb-4 border border-1 rounded shadow-sm">
      <Rating
        name="half-rating-read"
        defaultValue={rating}
        precision={0.5}
        readOnly
      />
      <p className="text">{bodyText}</p>
      <div className="d-flex align-items-center">
        <div className="profile-image-container">
          <img src="/assets/profile1.jpg" />
        </div>
        <div className="ps-2">
          <h5 className=" m-1">{name}</h5>
          <p className="m-1 fst-italic">{from}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
