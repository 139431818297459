import React from "react";

const SelectCountryOrState = ({
  label,
  elementId,
  optionsArray,
  value,
  onChange,
  onBlur,
  error,
}) => {
  console.log(`${elementId}`, optionsArray);
  return (
    <div className="p-2">
      <label htmlFor={elementId}>{label}</label>
      <select
        id={elementId}
        value={value}
        className="form-select"
        aria-label="Default select example"
        onChange={onChange}
        onBlur={onBlur}
      >
        <option key={`${elementId}_#`} value="#:#">
          {`--Select ${label}--`}
        </option>
        {optionsArray.map(({ isoCode, name }) => {
          return (
            <option key={`${elementId}_${name}`} value={`${isoCode}:${name}`}>
              {name}
            </option>
          );
        })}
      </select>
      <div id={`${elementId}Error`}>
        <span className="error">{error}</span>
      </div>
    </div>
  );
};

export default SelectCountryOrState;
