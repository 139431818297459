import React, { useState } from "react";
import Input from "../../common/Input";
import SelectType3 from "../../common/SelectType3";
import { useFormik } from "formik";
import { experienceDetailSchema } from "../../../validations/tutor";
import { getAssociations } from "../../../apis/other.apis";

const ExperienceDetailsForm = ({
  setShowExperienceDetailsForm,
  btnText,
  formik,
  editData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [associations, setAssociations] = useState([]);

  const experienceDetail = useFormik({
    initialValues: editData
      ? editData
      : {
          organization: "",
          designation: "",
          startDate: "",
          endDate: "",
          association: { _id: "#", name: "#" },
        },
    validationSchema: experienceDetailSchema,
    onSubmit: () => {
      if (btnText === "ADD") onADD();
      else onSAVE();
      setShowExperienceDetailsForm(false);
    },
  });

  const onADD = () => {
    let newArray = [
      ...formik.values.experienceDetails,
      experienceDetail.values,
    ];
    formik.setFieldValue("experienceDetails", newArray);
  };

  const onSAVE = () => {
    let updatedArray = [...formik.values.experienceDetails];
    updatedArray[editData.index] = experienceDetail.values;
    formik.setFieldValue("experienceDetails", updatedArray);
  };

  const onLoading = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const { data, isError } = await getAssociations();
      if (!isError) {
        setAssociations(data.associations);
      }
      setIsLoading(false);
    }
  };

  useState(() => {
    onLoading();
  }, []);

  return (
    <div className="container-fluid page-center-div-container">
      <div
        className="container-fluid vh-100"
        onClick={() => {
          console.log("clicked");
          setShowExperienceDetailsForm(false);
        }}
      ></div>
      <div className="page-center-div w-50 shadow rounded p-2">
        <div className="bg-dark-subtle">
          <span className="d-block fs-5 fw-bolder w-100 p-2">
            ADD Teaching and Professional Experience
          </span>
        </div>
        <div className="overflow-wrapper">
          <Input
            type="text"
            id="organization"
            label="Organization"
            value={experienceDetail.values.organization}
            onChange={experienceDetail.handleChange}
            onBlur={experienceDetail.handleBlur}
            error={
              experienceDetail.touched.organization &&
              experienceDetail.errors.organization
                ? experienceDetail.errors.organization
                : ""
            }
          />
          <Input
            type="text"
            id="designation"
            label="Designation"
            value={experienceDetail.values.designation}
            onChange={experienceDetail.handleChange}
            onBlur={experienceDetail.handleBlur}
            error={
              experienceDetail.touched.designation &&
              experienceDetail.errors.designation
                ? experienceDetail.errors.designation
                : ""
            }
          />
          <Input
            type="date"
            id="startDate"
            label="Start Date"
            value={experienceDetail.values.startDate}
            onChange={experienceDetail.handleChange}
            onBlur={experienceDetail.handleBlur}
            error={
              experienceDetail.touched.startDate &&
              experienceDetail.errors.startDate
                ? experienceDetail.errors.startDate
                : ""
            }
          />
          <Input
            type="date"
            id="endDate"
            label='End Date (Kindly avoid specifying an end date if you prefer selecting the "Till date" option")'
            value={experienceDetail.values.endDate}
            onChange={experienceDetail.handleChange}
            onBlur={experienceDetail.handleBlur}
            error={
              experienceDetail.touched.endDate &&
              experienceDetail.errors.endDate
                ? experienceDetail.errors.endDate
                : ""
            }
          />
          <SelectType3
            label="Association"
            elementId={"association"}
            optionsArray={associations}
            value={`${experienceDetail.values.association._id}:${experienceDetail.values.association.name}`}
            onChange={(e) => {
              let [_id, name] = e.target.value.split(":");
              experienceDetail.setFieldValue(`${e.target.id}`, {
                _id: Number(_id),
                name,
              });
            }}
            onBlur={(e) => {
              experienceDetail.setFieldTouched(
                `${e.target.id}`,
                { _id: true, name: true },
                true
              );
            }}
            error={
              experienceDetail.touched.association?.name &&
              experienceDetail.errors.association?.name
                ? experienceDetail.errors.association?.name
                : ""
            }
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-primary"
            onClick={experienceDetail.handleSubmit}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExperienceDetailsForm;
