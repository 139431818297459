import React, { useEffect, useState } from "react";
import Error from "./Error";

// const databaseSubjectsandSkills = [
//   { _id: 1, name: "English" },
//   { _id: 2, name: "Hindi" },
//   { _id: 3, name: "Math" },
//   { _id: 4, name: "Science" },
//   { _id: 5, name: "Java" },
//   { _id: 6, name: "PHP" },
// ];

const SearchDropdown = ({
  databaseSubjectsandSkills,
  setSelectedSkill,
  editSkill,
  error,
}) => {
  const [searchText, setSearchText] = useState(editSkill ? editSkill : "");
  const [showListItems, setShowListItems] = useState(false);
  const [databaseListItems, setDatabaseListItems] = useState([]);
  const [listItems, setListItems] = useState([]);

  const listItemClicked = (name, _id) => {
    console.log("name", name);
    console.log("_id", _id);
    setSearchText(name);
    setShowListItems(false);
    setSelectedSkill({ _id, name });
  };

  useEffect(() => {
    setDatabaseListItems(databaseSubjectsandSkills);
    setListItems(databaseSubjectsandSkills);
  }, []);
  return (
    <div className="mt-3">
      <label htmlFor="skill" className="mb-1">
        Subject/Skill (You teach)
      </label>
      <div className="d-flex w-100">
        <div className="input-group position-relative">
          <input
            type="text"
            id="skill"
            className="form-control"
            style={{
              boxShadow: "none !important",
              border: "none !important",
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                setShowListItems(false);
              } else {
                console.log(databaseListItems);
                let filteredListItems = databaseListItems.filter(
                  (databaseListItem) => {
                    return databaseListItem.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  }
                );
                console.log("filteredListItems", filteredListItems);
                if (filteredListItems.length > 0) {
                  setShowListItems(true);
                }
                setListItems(filteredListItems);
              }
            }}
          />
          {showListItems && (
            <div className="search-drop-down">
              {listItems.map((item) => {
                console.log("inside map");
                return (
                  <span
                    key={item._id}
                    className="d-block search-list-item"
                    value={item.name}
                    onClick={() => {
                      listItemClicked(item.name, item._id);
                    }}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
          )}
          <span
            className="input-group-text"
            onClick={() => {
              setListItems(databaseListItems);
              setShowListItems((prev) => {
                return !prev;
              });
            }}
          >
            <svg
              fill="#000000"
              height="10px"
              width="10px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 330 330"
              // xml:space="preserve"
            >
              <path
                id="XMLID_225_"
                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
              />
            </svg>
          </span>
        </div>
      </div>
      <Error id={"skill"} error={error} />
    </div>
  );
};

export default SearchDropdown;
