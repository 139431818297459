import { getRequest, postRequest, putRequest } from "./requests";
const STUDENT_SIGNUP_URL = `/v1/auth/student/email/sign-up`;
const STUDENT_LOGIN_URL = `/v1/auth/student/email/login`;
const STUDENT_OTP_VERIFY_URL = `/v1/auth/student/email/verify`;
const GET_STUDENT_DATA_URL = `/v1/student/data`;
const STUDENT_REQUIREMENTS_URL = "/v1/student/requirement";
const GET_STUDENT_USER_REQUIREMENTS_URL = "/v1/student/requirement/user";
const GET_STUDENTS_REQUIREMENTS_URL = "/v1/student/requirement/search";
const DECREASE_STUDENT_CREDITS = "/v1/student/credit/decrease";
const GET_STUDENT_CREDITS = "/v1/student/credit/search";
const GET_STUDENT_REQUIREMENT_BY_ID = "/v1/student/requirement";

// authentication apis
export const studentLogin = async (body, params) => {
  return await postRequest(STUDENT_LOGIN_URL, body, params);
};

export const studentSignup = async (body, params) => {
  return await postRequest(STUDENT_SIGNUP_URL, body, params);
};

export const studentOtpVerify = async (body, params) => {
  return await postRequest(STUDENT_OTP_VERIFY_URL, body, params);
};

// student apis
export const getStudentData = async (params) => {
  return await getRequest(GET_STUDENT_DATA_URL, params);
};

export const getLoggedInStudentRequirements = async (params) => {
  return await getRequest(GET_STUDENT_USER_REQUIREMENTS_URL, {
    ...params,
    status: 1,
  });
};

export const createStudentRequirement = async (body, params) => {
  return await postRequest(STUDENT_REQUIREMENTS_URL, body, params);
};

export const updateStudentRequirement = async (body, params) => {
  return await putRequest(STUDENT_REQUIREMENTS_URL, body, params);
};

export const decreaseStudentCredits = async (body, params) => {
  return await postRequest(DECREASE_STUDENT_CREDITS, body, params);
};

export const getStudentCredits = async (params) => {
  return await getRequest(GET_STUDENT_CREDITS, { ...params, user: true });
};

export const getStudentsRequirements = async (params) => {
  return await getRequest(GET_STUDENTS_REQUIREMENTS_URL, {
    ...params,
    status: 1,
  });
};

export const getStudentRequirementById = async (params) => {
  return await getRequest(GET_STUDENT_REQUIREMENT_BY_ID, params);
};
