import React from "react";

const AboutUs = () => {
  return (
    <div className="d-flex justify-content-center m-4">
      <div className="p-4">
        <div className="d-flex justify-content-center mb-2">
          <span className="fw-bold fs-3">About Us</span>
        </div>
        <p>
          Discover the power of findtutorhub.com, a trusted online platform
          embraced by students and educators worldwide. While we may not be the
          largest, our dedication sets us apart. Even as you explore other
          options, we strive to leave a lasting impression, earning your trust
          and referrals.
        </p>
        <p>
          Count on us for ongoing enhancements, swift response to feedback, and
          an expanding array of features. Whether you seek tutors or teaching
          opportunities, we're committed to fulfilling your needs.
        </p>
        <p>
          We have a strict policy of zero tolerance towards troublemakers,
          spammers, and profit-driven marketers who prioritize their gains above
          all else.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
