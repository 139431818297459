import React from "react";

const RegistrationProcess = () => {
  return (
    <div className="d-flex justify-content-center m-4">
      <div className="p-4">
        <div className="d-flex justify-content-center mb-2">
          <span className="fw-bold fs-3">Registration Process</span>
        </div>
        <h3>
          Registration is free on{" "}
          <a href="https://findtutorhub.com">FindTutorHub.com</a>
        </h3>

        <h3>Students:</h3>
        <ol>
          <li>
            <strong>Register</strong> on{" "}
            <a href="https://findtutorhub.com">FindTutorHub.com</a>
          </li>
          <li>
            <strong>Login</strong> to your account
          </li>
          <li>
            Click on the <strong>"Find Tutors"</strong> button
          </li>
          <li>
            Enter the desired skills (e.g., Math, Java, Physics) in the search
            box to view a list of available tutors
          </li>
          <li>
            <strong>Check tutor profiles</strong> and contact them via the Email
            or Phone button
          </li>
          <li>
            Ensure you have sufficient coin balance to access tutor contact
            details
          </li>
          <li>
            To purchase coins, please email us at{" "}
            <a href="mailto:findtutorhub@gmail.com">findtutorhub@gmail.com</a>
          </li>
        </ol>

        <h3>Tutors:</h3>
        <ol>
          <li>
            <strong>Register</strong> on FindTutorHub.com
          </li>
          <li>
            <strong>Login</strong> to your account
          </li>
          <li>
            Click on the <strong>"Tutors Jobs"</strong> button
          </li>
          <li>
            Enter the desired skills (e.g., Math, Java, Physics) in the search
            box to view a list of available tutor’s jobs
          </li>
          <li>
            <strong>Check tutor jobs</strong> and contact student via the Email
            or Phone button
          </li>
          <li>
            Ensure you have sufficient coin balance to access tutor contact
            details
          </li>
          <li>
            To purchase coins, please email us at{" "}
            <a href="mailto:findtutorhub@gmail.com">findtutorhub@gmail.com</a>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default RegistrationProcess;
