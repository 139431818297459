import React, { useContext, useEffect, useState } from "react";
import BasicDetails from "../../components/pages/CompleteProfile/BasicDetails";
import ChargesDetails from "../../components/pages/CompleteProfile/ChargesDetails";
import TeachingDetails from "../../components/pages/CompleteProfile/TeachingDetails";
import QualificationDetails from "../../components/pages/CompleteProfile/QualificationDetails";
import ExperienceDetails from "../../components/pages/CompleteProfile/ExperienceDetails";
import { Country } from "country-state-city";
import "../../styles/pages/Tutor/CompleteProfile/style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useFormik } from "formik";
import { completeProfileSchema } from "../../validations/tutor";
import moment from "moment";
import ContentContainer from "../../components/common/Portal/ContentContainer";
import QnADetails from "../../components/pages/CompleteProfile/QnADetails";
import { updateTutorProfile } from "../../apis/tutor.apis";

const TutorUpdateProfile = () => {
  const { authUserData, getAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);

  const onUpdateClicked = async () => {
    let {
      name,
      gender,
      dob,
      sCode,
      state,
      cCode,
      country,
      city,
      pincode,
      briefDescription,
      introAboutYourself,
    } = formik.values.basicDetails;
    let teachingDetailsArray = formik.values.teachingDetails.map((obj) => {
      return {
        skill: obj.skill._id,
        fromLevel: obj.fromLevel._id,
        toLevel: obj.toLevel._id,
      };
    });
    let qualificationDetailsArray = formik.values.qualificationDetails.map(
      (obj) => {
        let newObject = {
          ...obj,
          association: obj.association._id,
          degreeType: obj.degreeType._id,
        };
        if (newObject._id) {
          delete newObject._id;
          delete newObject.index;
        }
        return newObject;
      }
    );
    let experienceDetailsArray = formik.values.experienceDetails.map((obj) => {
      let newObject = {
        ...obj,
        association: obj.association._id,
      };
      if (newObject._id) {
        delete newObject._id;
        delete newObject.index;
      }
      return newObject;
    });
    let qna = { ...formik.values.qna };
    delete qna._id;

    try {
      const { data } = await updateTutorProfile({
        name,
        gender,
        dob,
        sCode,
        state,
        cCode,
        country,
        city,
        pincode,
        briefDescription,
        introAboutYourself,
        ...formik.values.chargesDetails,
        chargesType: formik.values.chargesDetails.chargesType,
        teachingDetails: teachingDetailsArray,
        qualifications: qualificationDetailsArray,
        experiences: experienceDetailsArray,
        qna,
      });
      console.log("data", data);
      await getAuthUserData();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      basicDetails: {
        name: authUserData ? authUserData.tutor.name : "",
        gender: authUserData ? authUserData.tutor.gender : "#",
        dob: authUserData
          ? moment(authUserData.tutor.dob).format("YYYY-MM-DD")
          : "",
        mobileCode: authUserData ? authUserData.tutor.mobileCode : "",
        mobileIsoCode: authUserData ? authUserData.tutor.mobileIsoCode : "",
        mobile: authUserData ? authUserData.tutor.mobile : "",
        email: authUserData ? authUserData.tutor.email : "",
        cCode: authUserData ? authUserData.tutor.cCode : "#",
        country: authUserData ? authUserData.tutor.country : "#",
        sCode: authUserData ? authUserData.tutor.sCode : "#",
        state: authUserData ? authUserData.tutor.state : "#",
        city: authUserData ? authUserData.tutor.city : "#",
        pincode: authUserData ? authUserData.tutor.pincode : "",
        briefDescription: authUserData
          ? authUserData.tutor.briefDescription
          : "",
        introAboutYourself: authUserData
          ? authUserData.tutor.introAboutYourself
          : "",
      },
      chargesDetails: {
        currency: authUserData ? authUserData.tutor.currency : "#",
        chargesType: authUserData ? authUserData.tutor.chargesType : "#",
        minFee: authUserData ? authUserData.tutor.minFee : 0,
        maxFee: authUserData ? authUserData.tutor.maxFee : 0,
        feeVaryDetails: authUserData ? authUserData.tutor.feeVaryDetails : "",
      },
      teachingDetails: authUserData ? authUserData.tutor.teachingDetails : [],
      qualificationDetails: authUserData
        ? authUserData.tutor.qualifications.map((qualification) => {
            return {
              ...qualification,
              startDate: qualification.startDate
                ? moment(qualification.startDate).format("YYYY-MM-DD")
                : "",
              endDate: qualification.endDate
                ? moment(qualification.endDate).format("YYYY-MM-DD")
                : "",
            };
          })
        : [],
      experienceDetails: authUserData
        ? authUserData.tutor.experiences.map((experience) => {
            return {
              ...experience,
              startDate: experience.startDate
                ? moment(experience.startDate).format("YYYY-MM-DD")
                : "",
              endDate: experience.endDate
                ? moment(experience.endDate).format("YYYY-MM-DD")
                : "",
            };
          })
        : [],
      qna:
        authUserData && authUserData.tutor.qna
          ? { ...authUserData.tutor.qna }
          : {
              // willing to travel to student
              isWTTTS: "",
              // how far can you travel
              travelDistance: 0,
              // is available for online teaching
              isAFOT: "",
              // do you have a digital pen
              dYHADP: "",
              // do you help with homework and assignments
              dYHWHAA: "",
              // are you currently a full time teacher employed by a School/College
              aYCAFTTEBASC: "",
              // opportunities you are interested in
              oYAII: "",
            },
    },
    validationSchema: completeProfileSchema,
    onSubmit: () => {
      onUpdateClicked().then((res) => {
        alert("Profile Updated Successfully");
        console.log("Profile Updated Successfully");
      });
    },
  });

  useEffect(() => {
    console.log("authUserData", authUserData);
    if (!authUserData) {
      navigate("/");
    } else {
      setCountries(Country.getAllCountries());
    }
  }, []);

  console.log("otuside use effect authUserData", authUserData);
  return authUserData ? (
    <ContentContainer>
      <div className="container-fluid mt-2 mb-2">
        <div className="d-flex p-2 flex-column">
          <div id="complete-profile" className="mt-2 mb-2">
            <div className="d-flex justify-content-between align-items-center rounded bg-info">
              <div className="d-flex align-items-center">
                <span className="fs-5 fw-bolder w-100 p-2">Update Profile</span>
              </div>
              <div className="me-3">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={formik.handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <BasicDetails
            basicDetails={formik.values.basicDetails}
            countries={countries}
            formik={formik}
          />
          <ChargesDetails
            chargesDetails={formik.values.chargesDetails}
            countries={countries}
            formik={formik}
          />
          <TeachingDetails
            teachingDetails={formik.values.teachingDetails}
            setFieldValue={formik.setFieldValue}
            formik={formik}
          />
          <QualificationDetails
            qualificationDetails={formik.values.qualificationDetails}
            setFieldValue={formik.setFieldValue}
            formik={formik}
          />
          <ExperienceDetails
            experienceDetails={formik.values.experienceDetails}
            formik={formik}
          />
          <QnADetails formik={formik} />
        </div>
        <div className="row">
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-danger"
              onClick={formik.handleSubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ContentContainer>
  ) : null;
};

export default TutorUpdateProfile;
