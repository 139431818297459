import React from "react";
import "../../styles/components/common/Footer/style.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="grid-container">
        <div className="grid-item grid-col-1">
          <div>
            <div
              className="center-elements-logo"
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                padding: "0px",
                margin: "0px",
              }}
            >
              FindTutorHub
            </div>
            <div
              className="center-elements-logo"
              style={{ padding: "0px", margin: "0px", fontSize: "10px" }}
            >
              Find a Tutor. Anytime. Anywhere.
            </div>
            <div style={{ marginTop: "20px" }}>
              <p>
                Discover the power of{" "}
                <Link
                  className="footer-links-item"
                  to="https://findtutorhub.com"
                >
                  findtutorhub.com
                </Link>
                , a trusted online platform embraced by students and educators
                worldwide.
              </p>
            </div>
          </div>
        </div>
        <div className="grid-item grid-col-2">
          <div>
            <div>
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                Quick Links
              </span>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item" to="/about-us">
                About Us
              </Link>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item" to="/contact-us">
                Contact Us
              </Link>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item" to="/registration-process">
                Registration Process
              </Link>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item" to="/payment-to-tutor">
                Payment To Tutor
              </Link>
            </div>
          </div>
        </div>
        <div className="grid-item grid-col-3">
          <div>
            <div>
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                Contact Us
              </span>
            </div>

            <div className="footer-links">
              Email:{" "}
              <Link
                className="footer-links-item"
                to={"mailto:findtutorhub@gmail.com"}
              >
                findtutorhub@gmail.com
              </Link>
            </div>
            {/* <div className="footer-links">
              <Link className="footer-links-item">About Us</Link>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item">About Us</Link>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item">About Us</Link>
            </div>
            <div className="footer-links">
              <Link className="footer-links-item">About Us</Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className="center-elements">
        <div>Copyright © 2024 FindTutorhub.com, All Rights Reserved</div>
      </div>
    </footer>
  );
};

export default Footer;
