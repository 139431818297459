import * as Yup from "yup";

export const studentDetailsSchema = Yup.object().shape({
  name: Yup.string().required(),
  gender: Yup.string().required(),
  mobileIsoCode: Yup.string().required("Please select mobile phone code"),
  mobileCode: Yup.string().required(),
  mobile: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .test("mobile", "Please provide correct mobile number", function (value) {
      let words = value.trim().length;
      return words === 10;
    }),
  email: Yup.string()
    .email("Please enter correct email id")
    .required("Please provide email id"),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf(
      [Yup.ref("password")],
      "Password & Confirm Password does not matched"
    ),
  isChecked: Yup.boolean().isTrue("Accept Terms & Conditions must be checked"),
});

export const updateSchema = Yup.object().shape({
  name: Yup.string().required("Please provide name"),
});

export const requirementValidationSchema = Yup.object().shape({
  helpFor: Yup.object().shape({
    _id: Yup.number().required(),
    name: Yup.string().required().notOneOf(["#"], "Please select help for"),
  }),
  meetingTypes: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.number().required(),
        name: Yup.string().required(),
      })
    )
    .min(1, "Please select at least one meeting type"),
  title: Yup.string().required("Please provide title"),
  description: Yup.string()
    .required("Please provide description")
    .notOneOf([""], "Please provide description")
    .test("description", "Please provide 10 words", function (value) {
      let words = value.trim().split(" ").length;
      return words > 9;
    }),
  skills: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.number().required(),
        name: Yup.string().required(),
      })
    )
    .min(1, "Please add at least one skill")
    .required(),
  languages: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.number().required(),
        name: Yup.string().required(),
      })
    )
    .min(1, "Please add at least one language")
    .required(),
  level: Yup.object().shape({
    _id: Yup.number().required(),
    name: Yup.string().required().notOneOf(["#"], "Please select level"),
  }),
  budgetCurrency: Yup.string()
    .required("Please select budget currency")
    .notOneOf(["#"], "Please select budget currency"),
  budget: Yup.number()
    .required()
    .min(1, "Please provide budget greater than zero"),
  budgetFrequency: Yup.object().shape({
    _id: Yup.number().required(),
    name: Yup.string()
      .required()
      .notOneOf(["#"], "Please select budget frequency"),
  }),
});
