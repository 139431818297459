import { BrowserRouter, useLocation } from "react-router-dom";
import AllRoutes from "./routes";
import AuthContext from "./context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { getStudentData } from "./apis/student.apis";
import { getTutorData } from "./apis/tutor.apis";
import Modal from "./components/common/Modal";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const [authUserData, setAuthUserData] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "My First Modal",
    body: "My Modal body",
    btnLabel: "Continue",
    btnHandler: () => {
      console.log("modal button clicked");
    },
  });

  const openModal = (modalData) => {
    document.body.style.position = "fixed";
    document.body.style.overflowY = "hidden";
    setModalContent({ ...modalData });
    setIsShowModal(true);
  };

  const setLocalStorageToken = (token, entityType) => {
    localStorage.setItem("token", token);
    localStorage.setItem("entityType", entityType);
  };

  const getLocalStorageEntityType = () => {
    return localStorage.getItem("entityType");
  };

  const getAuthUserData = async () => {
    const entityType = getLocalStorageEntityType();
    if (entityType) {
      let res;
      if (entityType === "student") {
        res = await getStudentData({});
      } else {
        res = await getTutorData({});
      }
      const { data, isError } = res;
      if (!isError) {
        setAuthUserData({
          [entityType]: data[entityType],
          entityType: entityType,
        });
        return data;
      }
    }
    return null;
  };

  const loadAuthUserData = async () => {
    await getAuthUserData();
    setIsGlobalLoading(false);
  };

  // useEffect(() => {
  //   let token = localStorage.getItem("token");
  //   let type = localStorage.getItem("type");
  //   if (token) {
  //     getAuthUserData(token, type);
  //   }
  // }, []);
  useEffect(() => {
    loadAuthUserData();
  }, []);
  return (
    <>
      <div>
        <Toaster position="bottom-center" reverseOrder={true} />
      </div>
      {!isGlobalLoading ? (
        <BrowserRouter>
          {/* <ScrollToTop /> */}
          {/* <HomeSkillContext.Provider value={{ skills, skill, onSkillChange }}> */}
          <AuthContext.Provider
            value={{
              authUserData,
              setAuthUserData,
              getAuthUserData,
              setLocalStorageToken,
              openModal,
            }}
          >
            <AllRoutes />
          </AuthContext.Provider>
          {/* </HomeSkillContext.Provider> */}
        </BrowserRouter>
      ) : (
        "Loading Page"
      )}
      {isShowModal && (
        <Modal
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          modalContent={modalContent}
        />
      )}
    </>
  );
}

export default App;
