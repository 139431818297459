import React from "react";
import Error from "./Error";

const SelectType3 = ({
  label,
  elementId,
  optionsArray,
  value,
  onChange,
  onBlur,
  error,
  disabled,
}) => {
  return (
    <div className="p-2">
      <label htmlFor={elementId}>{label}</label>

      <select
        id={elementId}
        value={value}
        className="form-select"
        aria-label="Default select example"
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        <option key={`${elementId}_#`} value="#:#">
          {`--Select ${label}--`}
        </option>
        {optionsArray.map(({ _id, name }) => {
          return (
            <option key={`${elementId}_${_id}`} value={`${_id}:${name}`}>
              {name}
            </option>
          );
        })}
      </select>
      <Error id={elementId} error={error} />
    </div>
  );
};

export default SelectType3;
