import React, { useState } from "react";
import "../../styles/components/common/SelectSkill/style.css";

const SelectSkill = ({
  skills,
  skill,
  setSkill,
  id = "skill",
  type = "text",
}) => {
  const [inputBox, setInputBox] = useState(
    skill.name !== "#" ? skill.name : ""
  );
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const filterSkills = (searchName) => {
    console.log("searchName", searchName);
    if (inputBox.length > 0) {
      let filteredSkills = skills.filter((skill) => {
        if (skill.name.toLowerCase().includes(searchName.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      console.log("filteredSkills", filteredSkills);
      return filteredSkills;
    } else {
      return [];
    }
  };

  let filteredSkills = filterSkills(inputBox);

  return (
    <>
      <div className="p-2 w-50">
        <input
          type={type}
          value={inputBox}
          onChange={(e) => {
            if (skill._id !== "#") {
              setSkill({ _id: "#", name: "#" });
            }
            setInputBox(e.target.value);
            setIsShowDropDown(true);
          }}
          placeholder="Type Skill"
          className="form-control skill-input"
          id={id}
          aria-describedby={`${id}Error`}
        />
        {/* <Error id={id} error={error} /> */}
        <div style={{ position: "relative" }}>
          {isShowDropDown && filteredSkills.length > 0 && (
            <div
              className="mt-1 border border-primary rounded"
              style={{
                position: "absolute",
                backgroundColor: "white",
                width: "100%",
                cursor: "pointer",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            >
              {filteredSkills.map((skill) => {
                return (
                  <div
                    key={skill._id}
                    className="px-2 my-1 skill"
                    onClick={() => {
                      setSkill({ _id: skill._id, name: skill.name });
                      console.log("skill selected clicked", skill.name);
                      setInputBox(skill.name);
                      setIsShowDropDown(false);
                    }}
                  >
                    {skill.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectSkill;
