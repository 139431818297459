import React, { useEffect, useRef, useState } from "react";
import Error from "./Error";
import "../../styles/components/common/Mobile/style.css";
import { Country } from "country-state-city";
import OutsideClickHandler from "react-outside-click-handler";

const Mobile = ({ phoneCountryCode, mobile }) => {
  let string, returnElement;
  const inputRef = useRef(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  return (
    <div className={`p-2 ${mobile.extraClass ? mobile.extraClass : ""}`}>
      <label htmlFor={mobile.id}>{mobile.label}</label>
      <OutsideClickHandler
        onOutsideClick={() => {
          console.log("You clicked outside of this compoent");
          setShowDropDown(false);
        }}
      >
        <div className="d-flex flex-row gap-1">
          <div
            className={`d-flex align-items-center gap-1 ${
              mobile.disabled ? "disabled" : ""
            }`}
            style={{
              border: "1px solid #dee2e6",
              borderRadius: "4px",
              padding: "0px 4px 0px 8px",
              width: "65px",
            }}
          >
            <input
              ref={inputRef}
              type={phoneCountryCode.type}
              maxLength={phoneCountryCode.maxLength}
              className="form-control"
              id={phoneCountryCode.id}
              aria-describedby={`${phoneCountryCode.id}Error`}
              value={phoneCountryCode.value}
              onChange={(e) => {
                setShowDropDown(true);
                phoneCountryCode.formik.setFieldValue(
                  phoneCountryCode.id2,
                  "",
                  true
                );
                // setIsoCODE(e.target.value);
                phoneCountryCode.onChange(e);
              }}
              onBlur={phoneCountryCode.onBlur}
              autoComplete="none"
              disabled={mobile.disabled}
              style={{ border: "none", boxShadow: "none", padding: "0" }}
              onClick={() => {
                setShowDropDown(true);
              }}
            />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                inputRef.current.focus();
                setShowDropDown((prev) => {
                  console.log("setShowDropDown prev", prev);
                  return !prev;
                });
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M128 192l128 128 128-128z"></path>
              </svg>
            </div>
          </div>
          <div
            className="w-100"
            style={{
              border: "1px solid #dee2e6",
              borderRadius: "4px",
            }}
          >
            <div className="input-group">
              {phoneCountryCode.value2 !== "" && (
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    id={`${mobile.id}Error`}
                    style={{
                      // fontSize: "14px",
                      border: "none",
                      color: "black",
                      backgroundColor: "#e9e9e9",
                      borderRadius: "4px",
                    }}
                  >
                    {`${phoneCountryCode.value2}`}
                  </span>
                </div>
              )}
              <input
                type={mobile.type}
                maxLength={mobile.maxLength}
                className="form-control shadow-none"
                id={mobile.id}
                aria-describedby={`${mobile.id}Error`}
                value={mobile.value}
                onChange={mobile.onChange}
                onBlur={mobile.onBlur}
                disabled={mobile.disabled}
                style={{ border: "none" }}
              />
              {/* <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3"> */}
            </div>
          </div>
        </div>

        {showDropDown && (
          <>
            <div style={{ position: "relative" }}>
              <div
                className="d-flex flex-column"
                style={{
                  position: "absolute",
                  top: "4px",
                  width: "100%",
                  maxHeight: "40vh",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  border: "1px solid #dee2e6",
                  borderRadius: "4px",
                }}
              >
                {countries.map(({ name, isoCode, phonecode }) => {
                  string = `${isoCode} ${name} ${phonecode}`;
                  returnElement = (
                    <div
                      key={string}
                      id={"countries_list"}
                      className="p-2"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        // setIsoCODE(phoneCountryCode.value);
                        await phoneCountryCode.formik.setFieldValue(
                          phoneCountryCode.id,
                          isoCode,
                          true
                        );
                        await phoneCountryCode.formik.setFieldValue(
                          phoneCountryCode.id2,
                          phonecode,
                          true
                        );
                        // setPhoneCODE(phonecode);
                        setShowDropDown(false);
                      }}
                    >
                      {string}
                    </div>
                  );
                  if (phoneCountryCode.value === "") return returnElement;
                  else {
                    if (
                      string
                        .toLowerCase()
                        .includes(phoneCountryCode.value.toLowerCase())
                    ) {
                      return returnElement;
                    }
                  }
                  return <></>;
                })}
              </div>
            </div>
          </>
        )}
      </OutsideClickHandler>
      <Error id={mobile.id} error={mobile.error} />
      <Error id={phoneCountryCode.id} error={phoneCountryCode.error} />
    </div>
  );
};

export default Mobile;
