import React, { useContext, useEffect, useState } from "react";
import BasicDetails from "../../components/pages/CompleteProfile/BasicDetails";
import ChargesDetails from "../../components/pages/CompleteProfile/ChargesDetails";
import TeachingDetails from "../../components/pages/CompleteProfile/TeachingDetails";
import QualificationDetails from "../../components/pages/CompleteProfile/QualificationDetails";
import ExperienceDetails from "../../components/pages/CompleteProfile/ExperienceDetails";
import { Country } from "country-state-city";
import "../../styles/pages/Tutor/CompleteProfile/style.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useFormik } from "formik";
import { completeProfileSchema } from "../../validations/tutor";
import moment from "moment";
import QnADetails from "../../components/pages/CompleteProfile/QnADetails";
import { updateTutorCompleteProfile } from "../../apis/tutor.apis";

const TutorProfile = ({ formik, countries }) => {
  return (
    <div className="container-fluid mt-2 mb-2">
      <div className="d-flex p-2 flex-column">
        <div id="complete-profile" className="mt-2 mb-2">
          <div className="d-flex justify-content-between align-items-center rounded bg-info">
            <div className="d-flex align-items-center">
              <span className="fs-5 fw-bolder w-100 p-2">Complete Profile</span>
            </div>
            <div className="me-3">
              <button
                type="button"
                className="btn btn-danger"
                onClick={formik.handleSubmit}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
        <BasicDetails
          basicDetails={formik.values.basicDetails}
          countries={countries}
          formik={formik}
        />
        <ChargesDetails
          chargesDetails={formik.values.chargesDetails}
          countries={countries}
          formik={formik}
        />
        <TeachingDetails
          teachingDetails={formik.values.teachingDetails}
          setFieldValue={formik.setFieldValue}
          formik={formik}
        />
        <QualificationDetails
          qualificationDetails={formik.values.qualificationDetails}
          setFieldValue={formik.setFieldValue}
          formik={formik}
        />
        <ExperienceDetails
          experienceDetails={formik.values.experienceDetails}
          formik={formik}
        />
        <QnADetails formik={formik} />
      </div>
      <div className="row">
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-danger"
            onClick={formik.handleSubmit}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

const CompleteProfile = () => {
  const { authUserData, getAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);

  const onSaveClicked = async (values) => {
    let {
      sCode,
      state,
      cCode,
      country,
      city,
      pincode,
      briefDescription,
      introAboutYourself,
    } = values.basicDetails;
    let teachingDetailsArray = values.teachingDetails.map((obj) => {
      return {
        skill: obj.skill._id,
        fromLevel: obj.fromLevel._id,
        toLevel: obj.toLevel._id,
      };
    });
    let qualificationDetailsArray = values.qualificationDetails.map((obj) => {
      return {
        ...obj,
        association: obj.association._id,
        degreeType: obj.degreeType._id,
      };
    });
    let experienceDetailsArray = values.experienceDetails.map((obj) => {
      return { ...obj, association: obj.association._id };
    });

    const { data, isError } = await updateTutorCompleteProfile({
      sCode,
      state,
      cCode,
      country,
      city,
      pincode,
      briefDescription,
      introAboutYourself,
      ...formik.values.chargesDetails,
      chargesType: formik.values.chargesDetails.chargesType,
      teachingDetails: teachingDetailsArray,
      qualifications: qualificationDetailsArray,
      experiences: experienceDetailsArray,
      qna: { ...formik.values.qna },
    });
    if (!isError) {
      await getAuthUserData();
      navigate("/");
    }
  };

  const formik = useFormik({
    initialValues: {
      basicDetails: {
        name: authUserData ? authUserData.tutor.name : "",
        gender: authUserData ? authUserData.tutor.gender : "#",
        dob: authUserData
          ? moment(authUserData.tutor.dob).format("YYYY-MM-DD")
          : "",
        mobileCode: authUserData ? authUserData.tutor.mobileCode : "",
        mobileIsoCode: authUserData ? authUserData.tutor.mobileIsoCode : "",
        mobile: authUserData ? authUserData.tutor.mobile : "",
        email: authUserData ? authUserData.tutor.email : "",
        cCode: "#",
        country: "#",
        sCode: "#",
        state: "#",
        city: "#",
        pincode: "",
        briefDescription: "",
        introAboutYourself: "",
      },
      chargesDetails: {
        currency: "#",
        chargesType: "#",
        minFee: 0,
        maxFee: 0,
        feeVaryDetails: "",
      },
      teachingDetails: [],
      qualificationDetails: [],
      experienceDetails: [],
      qna: {
        // willing to travel to student
        isWTTTS: "",
        // how far can you travel
        travelDistance: 0,
        // is available for online teaching
        isAFOT: "",
        // do you have a digital pen
        dYHADP: "",
        // do you help with homework and assignments
        dYHWHAA: "",
        // are you currently a full time teacher employed by a School/College
        aYCAFTTEBASC: "",
        // opportunities you are interested in
        oYAII: "",
      },
    },
    validationSchema: completeProfileSchema,
    onSubmit: onSaveClicked,
  });

  useEffect(() => {
    console.log("authUserData", authUserData);
    if (
      authUserData &&
      authUserData.entityType === "tutor" &&
      authUserData.tutor.isProfileCompleted === false
    ) {
      setCountries(Country.getAllCountries());
      setIsLoading(false);
    } else {
      navigate("/");
    }
  }, []);
  return isLoading ? (
    <div>Loading</div>
  ) : (
    <TutorProfile formik={formik} countries={countries} />
  );
};

export default CompleteProfile;
