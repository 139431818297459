import React from "react";
import Input from "../../common/Input";
import SelectCurrency from "../../common/SelectCurrency";
import Select from "../../common/Select";
import { chargesTypeList } from "../../../utils/constants";

const ChargesDetails = ({ chargesDetails, countries, formik }) => {
  return (
    <div id="basic-details" className="mt-3 ">
      <div className="bg-dark-subtle">
        <span className="d-block fs-5 fw-bolder p-2">Charges Details</span>
      </div>
      <div>
        <div className="row">
          <div className="col-2">
            <SelectCurrency
              label={"Currency"}
              elementId="chargesDetails.currency"
              value={chargesDetails.currency}
              optionsArray={countries}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.chargesDetails?.currency &&
                formik.errors.chargesDetails?.currency
                  ? formik.errors.chargesDetails?.currency
                  : ""
              }
            />
          </div>
          <div className="col-2">
            <Select
              label={"Charges Type"}
              elementId="chargesDetails.chargesType"
              value={chargesDetails.chargesType}
              optionsArray={chargesTypeList}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.chargesDetails?.chargesType &&
                formik.errors.chargesDetails?.chargesType
                  ? formik.errors.chargesDetails?.chargesType
                  : ""
              }
            />
          </div>

          <div className="col-2">
            <Input
              type="number"
              label="Min. Fee"
              id="chargesDetails.minFee"
              value={chargesDetails.minFee}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.chargesDetails?.minFee &&
                formik.errors.chargesDetails?.minFee
                  ? formik.errors.chargesDetails?.minFee
                  : ""
              }
            />
          </div>
          <div className="col-2">
            <Input
              type="number"
              label="Max. Fee"
              id="chargesDetails.maxFee"
              value={chargesDetails.maxFee}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.chargesDetails?.maxFee &&
                formik.errors.chargesDetails?.maxFee
                  ? formik.errors.chargesDetails?.maxFee
                  : ""
              }
            />
          </div>
          <div className="col-4">
            <Input
              type="text"
              label="Fee Vary Details"
              id="chargesDetails.feeVaryDetails"
              value={chargesDetails.feeVaryDetails}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.chargesDetails?.feeVaryDetails &&
                formik.errors.chargesDetails?.feeVaryDetails
                  ? formik.errors.chargesDetails?.feeVaryDetails
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargesDetails;
