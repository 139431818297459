import React, { useEffect, useState } from "react";
import ContentContainer from "../../components/common/Portal/ContentContainer";
import "../../styles/components/common/TableListWrapper/style.css";
import PostRequirement from "./PostRequirement";
import {
  getLoggedInStudentRequirements,
  updateStudentRequirement,
} from "../../apis/student.apis";

const TableListWrapper = ({
  tableHeaders,
  tableData,
  setType,
  setEditData,
  fetchAllUserRequirements,
}) => {
  const getSkillColumnData = (skills) => {
    return skills.map((skillObject) => {
      return <div>{`${skillObject.name}`}</div>;
    });
  };
  const deleteRequirement = async (requirementId) => {
    await updateStudentRequirement(
      { status: 2 },
      { requirement: requirementId }
    );
    await fetchAllUserRequirements();
  };
  return (
    <div className="list_table_wrapper">
      <table className="fixed_header">
        <thead>
          <tr>
            {tableHeaders.map((heading) => {
              return <th>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, index) => {
            return (
              <tr key={rowData._id}>
                <td>{rowData.title}</td>
                <td>{getSkillColumnData(rowData.skills)}</td>
                <td>{getSkillColumnData(rowData.languages)}</td>
                <td>{rowData.helpFor.name}</td>
                <td>{getSkillColumnData(rowData.meetingTypes)}</td>
                <td>{`${rowData.budgetCurrency} ${rowData.budget} ${rowData.budgetFrequency.name}`}</td>
                <td>{rowData.status === 1 ? "Active" : "Inactive"}</td>
                <td>
                  <div className="d-flex gap-2">
                    <button
                      className="btn"
                      style={{ backgroundColor: "rgb(213, 250, 215)" }}
                      onClick={() => {
                        setEditData(rowData);
                        setType("Edit");
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn"
                      style={{ backgroundColor: "rgb(213, 250, 215)" }}
                      onClick={() => {
                        setEditData(rowData);
                        setType("View");
                      }}
                    >
                      View
                    </button>
                    <button
                      className="btn"
                      style={{ backgroundColor: "rgb(213, 250, 215)" }}
                      onClick={() => {
                        deleteRequirement(rowData._id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </td>{" "}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Requirements = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requirements, setRequirements] = useState([]);
  const [type, setType] = useState("Post");
  const [editData, setEditData] = useState(null);

  const fetchAllUserRequirements = async () => {
    const { data, isError } = await getLoggedInStudentRequirements();
    if (!isError) {
      setRequirements(data.studentRequirements);
    }
  };

  const onLoading = async () => {
    await fetchAllUserRequirements();
    setIsLoading(false);
  };

  useEffect(() => {
    onLoading();
  }, []);

  return isLoading ? (
    <ContentContainer>"Loading Student Requirements"</ContentContainer>
  ) : (
    <ContentContainer>
      {type === "Post" ? (
        <TableListWrapper
          tableHeaders={[
            "Title",
            "Skills",
            "Languages",
            "Help For",
            "Meet Type",
            "Budget",
            "Status",
            "Actions",
          ]}
          tableData={requirements}
          setType={setType}
          setEditData={setEditData}
          fetchAllUserRequirements={fetchAllUserRequirements}
        />
      ) : null}
      {type === "Edit" || type === "View" ? (
        <PostRequirement
          type={type}
          setType={setType}
          editData={editData}
          setEditData={setEditData}
          fetchAllUserRequirements={fetchAllUserRequirements}
        />
      ) : null}
    </ContentContainer>
  );
};

export default Requirements;
