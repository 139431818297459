import React, { useContext } from "react";
import "../../../styles/components/common/LeftSidebar/style.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

const LeftSidebar = () => {
  const { authUserData, setAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="main_sidebar_wrapper">
      <div className="inside_main_sidebar_wrapper">
        <div className="logo_wrapper">
          <img
            src="/assets/logo.jpg"
            alt="logo image"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="menu_wrapper">
          {authUserData.entityType === "student" ? (
            <>
              <div
                className="menu_wrapper_single_item"
                onClick={() => {
                  navigate("/portal/student/post-requirement");
                }}
              >
                Post Requirement
              </div>
              <div
                className="menu_wrapper_single_item"
                onClick={() => {
                  navigate("/portal/student/requirements");
                }}
              >
                Requirements
              </div>
              <div
                className="menu_wrapper_single_item"
                onClick={() => {
                  navigate("/portal/student/credits");
                }}
              >
                Credit Logs
              </div>
            </>
          ) : (
            <>
              <div
                className="menu_wrapper_single_item"
                onClick={() => {
                  navigate("/portal/tutor/credits");
                }}
              >
                Credit Logs
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
