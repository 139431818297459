import React, { useState } from "react";
import TeachingDetailForm from "./TeachingDetailForm";
import TeachingDetailListItem from "./TeachingDetailListItem";
import Error from "../../common/Error";

const TeachingDetails = ({ teachingDetails, setFieldValue, formik }) => {
  const [showTeachingDetailsForm, setShowTeachingDetailsForm] = useState(false);
  const [formType, setFormType] = useState("");
  const [editData, setEditData] = useState({
    index: -1,
    skill: { _id: null, name: "" },
    fromLevel: { _id: null, name: "" },
    toLevel: { _id: null, name: "" },
  });

  const onEditClick = (data, index) => {
    console.log("Edit Click called");
    setEditData({ ...data, index });
    setFormType("Edit");
    setShowTeachingDetailsForm(true);
    if (!formik.touched.teachingDetails)
      formik.setFieldTouched("teachingDetails", true, true);
    let element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const onDeleteClick = (index) => {
    console.log("On Delete Called");
    let filteredData = teachingDetails.filter((object, i) => i !== index);
    if (!formik.touched.teachingDetails)
      formik.setFieldTouched("teachingDetails", true, true);
    setFieldValue("teachingDetails", filteredData, true);
  };

  return (
    <>
      <div id="teaching-details" className="mt-3">
        <div className="d-flex justify-content-between align-items-center bg-dark-subtle">
          <div className="d-flex align-items-center">
            <span className="fs-5 fw-bolder w-100 p-2">Teaching Details</span>
          </div>
          <div className="me-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShowTeachingDetailsForm(true);
                setFormType("Add");
                if (!formik.touched.teachingDetails)
                  formik.setFieldTouched("teachingDetails", true, true);
                let element = document.getElementById("root");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              ADD
            </button>
          </div>
        </div>
        <Error
          id="teachingDetailsError"
          error={
            formik.touched.teachingDetails && formik.errors.teachingDetails
              ? formik.errors.teachingDetails
              : ""
          }
        />
        {teachingDetails.map((data, index) => {
          return (
            <TeachingDetailListItem
              key={`teachingDetail_${index}`}
              data={data}
              index={index}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      </div>
      {showTeachingDetailsForm && formType === "Add" && (
        <TeachingDetailForm
          setShowTeachingDetailsForm={setShowTeachingDetailsForm}
          setFieldValue={setFieldValue}
          formik={formik}
          btnText="ADD"
        />
      )}
      {showTeachingDetailsForm && formType === "Edit" && (
        <TeachingDetailForm
          setShowTeachingDetailsForm={setShowTeachingDetailsForm}
          setFieldValue={setFieldValue}
          formik={formik}
          btnText="SAVE"
          editData={editData}
        />
      )}
    </>
  );
};

export default TeachingDetails;
