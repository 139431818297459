import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

const Modal = ({ setIsShowModal, modalContent }) => {
  const closeModal = () => {
    document.body.style.position = "";
    document.body.style.overflowY = "";
    setIsShowModal(false);
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          zIndex: "100",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <OutsideClickHandler onOutsideClick={closeModal}>
          <div className="bg-white p-3 rounded-1 border border-1 border-dark-subtle shadow-lg">
            <div style={{ width: "30vw" }}>
              <div className="pb-1 d-flex justify-content-center">
                <span className="fw-medium fs-5 text-danger">
                  {modalContent.title}
                </span>
              </div>
              <div
                className="py-4 fs-6 border-bottom border-top border-dark-subtle "
                style={{ minHeight: "100px" }}
              >
                {modalContent.body}
              </div>
              <div className="d-flex justify-content-center pt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    closeModal();
                    modalContent.btnHandler();
                  }}
                >
                  {modalContent.btnLabel}
                </button>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default Modal;
