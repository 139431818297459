import React, { useEffect, useState } from "react";
import Input from "../../common/Input";
import Select from "../../common/Select";
import SelectCountryOrState from "../../common/SelectCountryOrState";
import { gendersList } from "../../../utils/constants";
import { State, City } from "country-state-city";
import SelectCity from "../../common/SelectCity";
import Error from "../../common/Error";
import Mobile from "../../common/Mobile";

const BasicDetails = ({ basicDetails, countries, formik }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  console.log(
    formik.touched?.basicDetails?.state && formik.errors?.basicDetails?.state
      ? formik.errors?.basicDetails?.state
      : ""
  );

  const onChangeCountryOrState = (e) => {
    let [code, name] = e.target.value.split(":");
    if (e.target.id === "basicDetails.country") {
      console.log("country");
      formik.setFieldValue("basicDetails.cCode", code);
      formik.setFieldValue("basicDetails.country", name);
    } else {
      formik.setFieldValue("basicDetails.sCode", code);
      formik.setFieldValue("basicDetails.state", name);
    }
  };

  useEffect(() => {
    if (basicDetails.cCode === "#") {
      setStates([]);
      formik.setFieldValue("basicDetails.sCode", "#");
      formik.setFieldValue("basicDetails.state", "#");
    } else if (basicDetails.cCode !== formik.initialValues.basicDetails.cCode) {
      setStates(State.getStatesOfCountry(basicDetails.cCode));
      formik.setFieldValue("basicDetails.sCode", "#");
      formik.setFieldValue("basicDetails.state", "#");
    } else {
      console.log("executed states get");
      setStates(State.getStatesOfCountry(basicDetails.cCode));
    }
  }, [basicDetails.cCode]);

  useEffect(() => {
    if (basicDetails.sCode === "#") {
      setCities([]);
      formik.setFieldValue("basicDetails.city", "#");
    } else if (basicDetails.sCode !== formik.initialValues.basicDetails.sCode) {
      setCities(City.getCitiesOfState(basicDetails.cCode, basicDetails.sCode));
      formik.setFieldValue("basicDetails.city", "#");
    } else {
      console.log("executed cities get");
      setCities(City.getCitiesOfState(basicDetails.cCode, basicDetails.sCode));
    }
  }, [basicDetails.sCode]);

  return (
    <div id="basic-details">
      <div className="bg-dark-subtle">
        <span className="d-block fs-5 fw-bolder w-100 p-2">BASIC DETAILS</span>
      </div>
      <div>
        <div className="row">
          <div className="col-4">
            <Input
              type="text"
              label="Name"
              id="basicDetails.name"
              value={basicDetails.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errMsg=""
            />
          </div>
          <div className="col-2">
            <Select
              label="Gender"
              elementId="basicDetails.gender"
              value={basicDetails.gender}
              optionsArray={gendersList}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-2">
            <Input
              type="date"
              label="Date of Birth"
              id="basicDetails.dob"
              value={basicDetails.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errMsg={""}
            />
          </div>
          <div className="col-4">
            <Mobile
              phoneCountryCode={{
                formik,
                id: "basicDetails.mobileIsoCode",
                value: basicDetails.mobileIsoCode,
                id2: "basicDetails.mobileCode",
                value2: basicDetails.mobileCode,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                error:
                  formik.touched.basicDetails?.mobileIsoCode &&
                  formik.errors.basicDetails?.mobileIsoCode
                    ? formik.errors.basicDetails?.mobileIsoCode
                    : "",
              }}
              mobile={{
                type: "text",
                maxLength: "10",
                id: "basicDetails.mobile",
                label: "Mobile",
                value: basicDetails.mobile,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
                disabled: true,
                error:
                  formik.touched.basicDetails?.mobile &&
                  formik.errors.basicDetails?.mobile
                    ? formik.errors.basicDetails?.mobile
                    : "",
              }}
            />
            {/* <Input
              type="text"
              label="Mobile"
              id="basicDetails.mobile"
              maxLength={10}
              value={basicDetails.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={true}
            /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <Input
              type="email"
              label="Email"
              id="basicDetails.email"
              value={basicDetails.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={true}
            />
          </div>
          <div className="col-2">
            <SelectCountryOrState
              label="Country"
              elementId={"basicDetails.country"}
              value={`${basicDetails.cCode}:${basicDetails.country}`}
              optionsArray={countries}
              onChange={onChangeCountryOrState}
              onBlur={formik.handleBlur}
              error={
                formik.touched.basicDetails?.country &&
                formik.errors.basicDetails?.country
                  ? formik.errors.basicDetails?.country
                  : ""
              }
            />
          </div>
          <div className="col-2">
            <SelectCountryOrState
              label="State"
              elementId={"basicDetails.state"}
              value={`${basicDetails.sCode}:${basicDetails.state}`}
              optionsArray={states}
              onChange={onChangeCountryOrState}
              onBlur={formik.handleBlur}
              error={
                formik.touched.basicDetails?.state &&
                formik.errors.basicDetails?.state
                  ? formik.errors.basicDetails?.state
                  : ""
              }
            />
          </div>
          <div className="col-2">
            <SelectCity
              label={"City"}
              elementId="basicDetails.city"
              value={`${basicDetails.city}`}
              optionsArray={cities}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.basicDetails?.city &&
                formik.errors.basicDetails?.city
                  ? formik.errors.basicDetails?.city
                  : ""
              }
            />
          </div>
          <div className="col-2">
            <Input
              type="text"
              label="Pincode"
              id="basicDetails.pincode"
              maxLength={6}
              value={basicDetails.pincode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.basicDetails?.pincode &&
                formik.errors.basicDetails?.pincode
                  ? formik.errors.basicDetails?.pincode
                  : ""
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Input
              type="text"
              label="Brief Introduction about Yourself (max. 70 characters)"
              id="basicDetails.briefDescription"
              value={basicDetails.briefDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.basicDetails?.briefDescription &&
                formik.errors.basicDetails?.briefDescription
                  ? formik.errors.basicDetails?.briefDescription
                  : ""
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="p-2">
              <div className="form-group">
                <label htmlFor="basicDetails.introAboutYourself">
                  Introduction About Yourself (min. 50 words){" "}
                  {basicDetails.introAboutYourself.trim() !== "" &&
                  basicDetails.introAboutYourself.trim().split(" ").length <
                    50 &&
                  basicDetails.introAboutYourself.trim().split(" ").length >= 0
                    ? `${
                        50 -
                        basicDetails.introAboutYourself.trim().split(" ").length
                      } words left`
                    : ``}
                </label>
                <textarea
                  className="form-control"
                  id="basicDetails.introAboutYourself"
                  value={basicDetails.introAboutYourself}
                  rows="6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                <Error
                  id={"basicDetails.introAboutYourself"}
                  error={
                    formik.touched.basicDetails?.introAboutYourself &&
                    formik.errors.basicDetails?.introAboutYourself
                      ? formik.errors.basicDetails?.introAboutYourself
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
