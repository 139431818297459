import React, { useState } from "react";
import QualificationDetailForm from "./QualificationDetailForm";
import QualificationDetailListItem from "./QualificationDetailListItem";
import Error from "../../common/Error";

const QualificationDetails = ({
  qualificationDetails,
  setFieldValue,
  formik,
}) => {
  const [showQualificationDetailsForm, setShowQualificationDetailsForm] =
    useState(false);

  const [formType, setFormType] = useState("");
  const [editData, setEditData] = useState({});

  const onEditClick = (data, index) => {
    console.log("Edit Click called");
    setEditData({ ...data, index });
    setFormType("Edit");
    setShowQualificationDetailsForm(true);
    if (!formik.touched.qualificationDetails)
      formik.setFieldTouched("qualificationDetails", true, true);
    let element = document.getElementById("root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const onDeleteClick = (index) => {
    let filteredData = qualificationDetails.filter((object, i) => i !== index);
    if (!formik.touched.qualificationDetails)
      formik.setFieldTouched("qualificationDetails", true, true);
    setFieldValue("qualificationDetails", filteredData, true);
  };

  return (
    <>
      <div id="qualification-details" className="mt-3">
        <div className="d-flex justify-content-between align-items-center bg-dark-subtle">
          <div className="d-flex align-items-center">
            <span className="fs-5 fw-bolder w-100 p-2">
              Qualification Details
            </span>
          </div>
          <div className="me-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShowQualificationDetailsForm(true);
                setFormType("Add");
                if (!formik.touched.qualificationDetails)
                  formik.setFieldTouched("qualificationDetails", true, true);
                let element = document.getElementById("root");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              ADD
            </button>
          </div>
        </div>
        <Error
          id="qualificationDetailsError"
          error={
            formik.touched.qualificationDetails &&
            formik.errors.qualificationDetails
              ? formik.errors.qualificationDetails
              : ""
          }
        />
        {qualificationDetails.map((data, index) => {
          return (
            <QualificationDetailListItem
              key={`qualificationDetail_${index}`}
              data={data}
              index={index}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      </div>
      {showQualificationDetailsForm && formType === "Add" && (
        <QualificationDetailForm
          setShowQualificationDetailsForm={setShowQualificationDetailsForm}
          btnText="ADD"
          formik={formik}
        />
      )}
      {showQualificationDetailsForm && formType === "Edit" && (
        <QualificationDetailForm
          setShowQualificationDetailsForm={setShowQualificationDetailsForm}
          btnText="SAVE"
          editData={editData}
          formik={formik}
        />
      )}
    </>
  );
};

export default QualificationDetails;
