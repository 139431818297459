import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSkills } from "../apis/other.apis";
import { getStudentsRequirements } from "../apis/student.apis";
import SelectSkill from "../components/common/SelectSkill";

const RequirementList = ({ requirement }) => {
  return (
    <div className="d-flex justify-content-center mb-4">
      <div className="w-75">
        <div className="row ">
          <div className="col-12">
            <span
              className="fs-5 fw-semibold me-2 text-success"
              // onClick={() => {
              //   navigate(`/student/requirement/${requirement._id}`);
              // }}
            >
              <Link to={`/student/requirement/${requirement._id}`}>
                {requirement.title}
              </Link>
            </span>
          </div>
          <div>
            <span className="fs-5 fw-bolder" style={{ color: "green" }}>
              {/* {`(${requirement.helpFor?.name}-${requirement.meetType?.name}) for ${requirement.level?.name}`} */}
              {`${requirement.level?.name}`}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {requirement.skills.map((skill, index) => {
              return (
                <span key={index} className="me-2 border p-1 fw-semibold">
                  {skill.name}
                </span>
              );
            })}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <p>{`${requirement.description.trim().slice(0, 500)}...`}</p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {requirement.country ? (
              <span
                className="me-2 border p-1 rounded shadow bg-success fw-semibold"
                style={{ fontSize: "14px" }}
              >
                {`${requirement.city}, ${requirement.state}, ${requirement.country}`}
              </span>
            ) : null}
            <span
              className="me-2 border p-1 rounded shadow bg-warning fw-semibold"
              style={{ fontSize: "14px" }}
            >
              {`${requirement.budgetCurrency} ${requirement.budget} ${requirement.budgetFrequency.name}`}
            </span>
            <span
              className="me-2 border p-1 rounded shadow bg-secondary-subtle fw-semibold"
              style={{ fontSize: "14px" }}
            >
              {requirement.languages
                .map((languageObject) => {
                  return languageObject.name;
                })
                .join(", ")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const StudentRequirements = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState({ _id: "#", name: "#" });
  const [studentsRequirements, setStudentsRequirements] = useState([]);

  const searchRequirements = async () => {
    console.log("searchedStudents");
    let res;
    if (skill._id !== "#")
      res = await getStudentsRequirements({ skills: skill._id });
    else res = await getStudentsRequirements();
    setStudentsRequirements(res.data.studentRequirements);
  };

  const handleSearchClick = async () => {
    setIsLoading(true);
    await searchRequirements();
    setIsLoading(false);
  };

  const onLoading = async () => {
    if (isLoading) {
      let { data, isError } = await getSkills();
      !isError && setSkills(data.skills);
      await searchRequirements();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onLoading();
  }, []);

  return !isLoading ? (
    <div className="w-75 ms-auto me-auto">
      <div className="d-flex justify-content-center m-4">
        <SelectSkill skills={skills} skill={skill} setSkill={setSkill} />
        <button className="btn btn-success my-2" onClick={handleSearchClick}>
          <span className="fw-semibold">Search</span>
        </button>
      </div>

      {studentsRequirements.length > 0 &&
        studentsRequirements.map((requirement) => {
          return (
            <RequirementList key={requirement._id} requirement={requirement} />
          );
        })}
    </div>
  ) : null;
};

export default StudentRequirements;
