import React from "react";
import "../../styles/components/common/Error/style.css";

const Error = ({ id, error }) => {
  return (
    <div id={`${id}Error`}>
      <span className="error">{error}</span>
    </div>
  );
};

export default Error;
