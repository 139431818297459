import { getRequest, postRequest, putRequest } from "./requests";
const TUTOR_SIGNUP_URL = `/v1/auth/tutor/email/sign-up`;
const TUTOR_LOGIN_URL = `/v1/auth/tutor/email/login`;
const TUTOR_OTP_VERIFY_URL = `/v1/auth/tutor/email/verify`;
const GET_TUTOR_DATA_URL = `/v1/tutor/data`;
const UPDATE_TUTOR_COMPLETE_PROFILE_URL = `/v1/tutor/complete-profile`;
const UPDATE_TUTOR_PROFILE_URL = `/v1/tutor`;
const GET_TUTORS_URL = "/v1/tutor/search";
const GET_TUTOR_BY_ID = "/v1/tutor";
const VIEW_TUTOR_PROFILE = "/v1/tutor/profile";
const DECREASE_TUTOR_CREDITS = "/v1/tutor/credit/decrease";
const GET_TUTOR_CREDITS = "/v1/tutor/credit/search";

// authentication apis
export const tutorLogin = async (body, params) => {
  return await postRequest(TUTOR_LOGIN_URL, body, params);
};

export const tutorSignup = async (body, params) => {
  return await postRequest(TUTOR_SIGNUP_URL, body, params);
};

export const tutorOtpVerify = async (body, params) => {
  return await postRequest(TUTOR_OTP_VERIFY_URL, body, params);
};

// tutor apis
export const getTutorData = async (params) => {
  return await getRequest(GET_TUTOR_DATA_URL, params);
};

export const updateTutorCompleteProfile = async (body, params) => {
  return await putRequest(UPDATE_TUTOR_COMPLETE_PROFILE_URL, body, params);
};

export const updateTutorProfile = async (body, params) => {
  return await putRequest(UPDATE_TUTOR_PROFILE_URL, body, params);
};

export const getTutors = async (params) => {
  return await getRequest(GET_TUTORS_URL, {
    ...params,
    isProfileCompleted: true,
  });
};

export const getTutorById = async (params) => {
  return await getRequest(GET_TUTOR_BY_ID, params);
};

export const viewTutorProfile = async (params) => {
  return await getRequest(VIEW_TUTOR_PROFILE, params);
};

export const decreaseTutorCredits = async (body, params) => {
  return await postRequest(DECREASE_TUTOR_CREDITS, body, params);
};

export const getTutorCredits = async (params) => {
  return await getRequest(GET_TUTOR_CREDITS, { ...params, user: true });
};
