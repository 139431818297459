import React, { useState } from "react";
import Error from "../../common/Error";
import TwoRadioButtons from "../../TwoRadioButtons";

const QnADetails = ({ formik }) => {
  return (
    <div id="experience-details" className="mt-3">
      <div className="d-flex justify-content-between align-items-center bg-dark-subtle">
        <div className="d-flex align-items-center">
          <span className="fs-5 fw-bolder w-100 p-2">QnAs</span>
        </div>
      </div>
      <TwoRadioButtons
        qLabel={"Willing to travel to student?"}
        name={"isWTTTS"}
        formik={formik}
      />
      {formik.values.qna.isWTTTS === "Y" ? (
        <div className="row">
          <div className="col-6">
            <div className="p-2">{"How far can you travel (km)?"}</div>
          </div>
          <div className="col-2">
            <div className="p-2">
              <input
                type={"number"}
                className="form-control"
                id={"qna.travelDistance"}
                value={formik.values.qna.travelDistance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Error
                id={"travelDistance"}
                error={
                  formik.touched.qna !== undefined &&
                  formik.touched.qna["travelDistance"] !== undefined &&
                  formik.errors.qna !== undefined &&
                  formik.errors.qna["travelDistance"] !== undefined
                    ? formik.errors.qna["travelDistance"]
                    : ""
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      <TwoRadioButtons
        qLabel={"Is available for online teaching?"}
        name={"isAFOT"}
        formik={formik}
      />
      <TwoRadioButtons
        qLabel={"Do you have a digital pen?"}
        name={"dYHADP"}
        formik={formik}
      />
      <TwoRadioButtons
        qLabel={"Do you help with homework and assignments?"}
        name={"dYHWHAA"}
        formik={formik}
      />
      <TwoRadioButtons
        qLabel={
          "Are you currently a full time teacher employed by a School/College?"
        }
        name={"aYCAFTTEBASC"}
        formik={formik}
      />
      <div className="row">
        <div className="col-6">
          <div className="p-2">
            {"Opportunities you are interested in?"}
            <Error
              id={"oYAII"}
              error={
                formik.touched.qna !== undefined &&
                formik.touched.qna["oYAII"] !== undefined &&
                formik.errors.qna !== undefined &&
                formik.errors.qna["oYAII"] !== undefined
                  ? formik.errors.qna["oYAII"]
                  : ""
              }
            />
          </div>
        </div>
        <div className="col-6">
          <div className="p-2">
            <input
              type={"text"}
              className="form-control"
              id={"qna.oYAII"}
              value={formik.values.qna.oYAII}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
      </div>

      {/* <Error
          id="experienceDetailsError"
          error={
            formik.touched.experienceDetails && formik.errors.experienceDetails
              ? formik.errors.experienceDetails
              : ""
          }
        /> */}
    </div>
  );
};

export default QnADetails;
