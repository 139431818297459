import "../styles/pages/Login/style.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { loginSchema } from "../validations/login";
import Input from "../components/common/Input";
import { studentLogin } from "../apis/student.apis";
import { tutorLogin } from "../apis/tutor.apis";
import VerifyComponent from "../components/pages/VerifyComponent";

const Login = ({ formik, activeTab, setActiveTab, isShowTabs }) => {
  return (
    <div className="container-fluid my-5 mx-auto bg-white shadow px-4 py-4 w-50 shadow rounded-2 border-top">
      <h3 className="text-center">
        {`${
          !isShowTabs ? (activeTab === "student" ? "Student " : "Tutor ") : ""
        }`}
        Login
      </h3>
      <hr />
      {isShowTabs ? (
        <div className="d-flex w-100">
          {/* <button className="login-tabs tab-inactive fs-5 fw-medium"> */}
          <button
            className={`login-tabs ${
              activeTab === "student"
                ? "left-tab-active text-primary"
                : "tab-inactive"
            } fs-5 fw-medium`}
            onClick={() => {
              setActiveTab("student");
            }}
          >
            Student
          </button>
          <button
            className={`login-tabs ${
              activeTab === "tutor"
                ? "right-tab-active text-primary"
                : "tab-inactive"
            } fs-5 fw-medium`}
            onClick={() => {
              setActiveTab("tutor");
            }}
          >
            Tutor
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex flex-column">
        <form onSubmit={formik.handleSubmit}>
          <Input
            extraClass={"my-3"}
            type="email"
            id="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <Input
            extraClass={"my-3"}
            type="password"
            id="password"
            label="Passsword"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />

          <div className="my-3 d-flex justify-content-between align-items-center">
            <button type="submit" className="btn btn-primary w-25 p-2">
              Login
            </button>
            <span
              className="text-primary fw-bold fs-5"
              style={{ textShadow: "1px 1px #cfd5eace" }}
            >
              Forgot Password
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

const LoginPage = ({
  isNavigate,
  setStateFn,
  defaultTab,
  isShowTabs = true,
}) => {
  const { setLocalStorageToken, getAuthUserData } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(
    defaultTab === undefined ? "student" : defaultTab
  );

  const [stateObject, setStateObject] = useState({
    fullHash: "",
    isShowVerifyOtp: false,
    toRoute: "/",
  });
  const navigate = useNavigate();

  const onLoginClicked = async (values) => {
    const { email, password } = values;

    let res;
    if (activeTab === "student") {
      res = await studentLogin({ email, password });
    } else {
      res = await tutorLogin({ email, password });
    }
    console.log("login res", res);
    const { data, isError } = res;
    if (!isError) {
      if (data.fullHash) {
        if (activeTab === "student") {
          setStateObject({
            fullHash: data.fullHash,
            isShowVerifyOtp: true,
            toRoute: "/",
          });
        } else {
          setStateObject({
            fullHash: data.fullHash,
            isShowVerifyOtp: true,
            toRoute: "/tutor/complete-profile",
          });
        }
      } else {
        // load user data if got token
        setLocalStorageToken(data.token, activeTab);
        let res = await getAuthUserData();
        if (isNavigate) {
          if (activeTab === "tutor") {
            const isProfileCompleted = res[activeTab].isProfileCompleted;
            if (isProfileCompleted) {
              navigate("/");
            } else {
              navigate("/tutor/complete-profile");
            }
          } else {
            navigate("/");
          }
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: onLoginClicked,
  });

  return stateObject.isShowVerifyOtp ? (
    <VerifyComponent
      entityType={activeTab}
      fullHash={stateObject.fullHash}
      email={formik.values.email}
      toRoute={stateObject.toRoute}
      isNavigate={isNavigate}
    />
  ) : (
    <Login
      formik={formik}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      isShowTabs={isShowTabs}
    />
  );
};

export default LoginPage;
