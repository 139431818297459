import React from "react";

const ContactUs = () => {
  return (
    <div className="d-flex justify-content-center m-4">
      <div>
        For buying credits and any feedback/queries, reach out at{" "}
        <b>findtutorhub@gmail.com</b>
      </div>
    </div>
  );
};

export default ContactUs;
