import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getSkills } from "../apis/other.apis";

const SkillContext = createContext();

const HomeSkillContext = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState({ _id: "#", name: "#" });

  const onLoading = async () => {
    if (!isLoading) {
      setIsLoading(true);
      let { data, isError } = await getSkills();
      if (!isError) {
        setSkills(data.skills);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onLoading();
  }, []);
  return (
    <SkillContext.Provider value={{ skills, skill, setSkill }}>
      <Outlet />
    </SkillContext.Provider>
  );
};

export { HomeSkillContext, SkillContext };
