import Input from "../../components/common/Input";
import "../../styles/pages/TutorSignUp/style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { tutorDetailsSchema } from "../../validations/tutor";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import Error from "../../components/common/Error";
import { tutorSignup } from "../../apis/tutor.apis";
import VerifyComponent from "../../components/pages/VerifyComponent";
import Mobile from "../../components/common/Mobile";

const SignUp = ({ formik }) => {
  return (
    <div className="container-fluid signup-form-container shadow border-top">
      {/* <div className="signup-form-heading p-2">
    <span className="fs-5 fw-semibold">BASIC DETAILS</span>
  </div> */}
      <div className="mb-3 text-center">
        <span className="fs-3 fw-bold text-primary">Tutor Registration</span>
      </div>
      <div className="signup-form-inner">
        <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   submitClicked().then((res) => {
          //     console.log("res", res);
          //     if (res) {
          //       navigate("/verify", {
          //         state: {
          //           fromPage: "signUp",
          //           fullHash: res.fullHash,
          //           email: formik.values.email,
          //           redirectTo: "tutor",
          //         },
          //       });
          //     }
          //   });
          //   // .catch((e) => console.log(e));
          // }}
          onSubmit={formik.handleSubmit}
          // onSubmit={(e) => {
          //   submitClicked(e);
          // }}
        >
          <Input
            type="text"
            id="name"
            label="Full Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
          />
          <div className="p-2">
            <label>Gender</label>
            <div className="d-flex gap-4">
              <div className="form-check d-flex align-content-center gap-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="flexRadioDefault1"
                  checked={formik.values.gender === "M"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value="M"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Male
                </label>
              </div>
              <div className="form-check d-flex align-content-center gap-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="flexRadioDefault2"
                  checked={formik.values.gender === "F"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value="F"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Female
                </label>
              </div>
              <div className="form-check d-flex align-content-center gap-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="flexRadioDefault3"
                  checked={formik.values.gender === "T"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value="T"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  Transgender
                </label>
              </div>
            </div>
            <Error
              id={"gender"}
              error={
                formik.touched.gender && formik.errors.gender
                  ? formik.errors.gender
                  : ""
              }
            />
          </div>
          <Input
            type="date"
            id="dob"
            label="Date of Birth"
            value={formik.values.dob}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.dob && formik.errors.dob ? formik.errors.dob : ""
            }
          />
          {/* <Input
            type="text"
            maxLength="10"
            id="mobile"
            label="Mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mobile && formik.errors.mobile
                ? formik.errors.mobile
                : ""
            }
          /> */}
          <Mobile
            phoneCountryCode={{
              formik,
              id: "mobileIsoCode",
              value: formik.values.mobileIsoCode,
              id2: "mobileCode",
              value2: formik.values.mobileCode,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error:
                formik.touched.mobileIsoCode && formik.errors.mobileIsoCode
                  ? formik.errors.mobileIsoCode
                  : "",
            }}
            mobile={{
              type: "text",
              maxLength: "10",
              id: "mobile",
              label: "Mobile",
              value: formik.values.mobile,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error:
                formik.touched.mobile && formik.errors.mobile
                  ? formik.errors.mobile
                  : "",
            }}
          />
          <Input
            type="email"
            id="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <Input
            type="password"
            id="password"
            label="Passsword"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />
          <Input
            type="password"
            id="confirmPassword"
            label="Confirm Passsword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : ""
            }
          />
          <div className="p-2">
            <div className="form-check d-flex align-content-center gap-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="isChecked"
                checked={formik.values.isChecked}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.isChecked && formik.errors.isChecked
                    ? formik.errors.isChecked
                    : ""
                }
              />
              <label className="form-check-label" htmlFor="isChecked">
                Accept Terms & Conditions
              </label>
            </div>
            {formik.touched.isChecked && formik.errors.isChecked && (
              <div className="pb-2" style={{ color: "red", fontSize: "12px" }}>
                {formik.errors.isChecked}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-primary mt-2 w-25">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const TutorSignUp = () => {
  const [fullHash, setFullHash] = useState("");
  const [isShowVerifyOtp, setIsShowVerifyOtp] = useState(false);

  const submitClicked = async (values) => {
    const {
      name,
      gender,
      dob,
      mobileCode,
      mobileIsoCode,
      mobile,
      email,
      password,
    } = values;

    const { data, isError } = await tutorSignup({
      name,
      gender,
      dob,
      mobileCode,
      mobileIsoCode,
      mobile,
      email,
      password,
    });
    console.log("data", data);

    if (!isError) {
      setFullHash(data.fullHash);
      setIsShowVerifyOtp(true);
    }

    // navigate("/verify", {
    //   state: {
    //     fromPage: "signUp",
    //     fullHash: data.fullHash,
    //     email: formik.values.email,
    //     redirectTo: "tutor",
    //   },
    // });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      dob: "",
      email: "",
      mobileCode: "91",
      mobileIsoCode: "IN",
      mobile: "",
      password: "",
      confirmPassword: "",
      isChecked: false,
    },
    validationSchema: tutorDetailsSchema,
    onSubmit: submitClicked,
  });

  // const showToast = () => {
  //   let errorKeys = Object.keys(formik.errors);
  //   if (formik.errors[errorKeys[0]]) {
  //     toast.error(formik.errors[errorKeys[0]]);
  //   }
  // };

  // useEffect(() => {
  //   console.log(formik.submitCount);
  //   if (formik.submitCount > 0 && Object.keys(formik.errors).length === 1)
  //     showToast();
  // }, [formik.submitCount]);

  return isShowVerifyOtp ? (
    <VerifyComponent
      entityType="tutor"
      fullHash={fullHash}
      email={formik.values.email}
      toRoute={"/tutor/complete-profile"}
      isNavigate={true}
    />
  ) : (
    <SignUp formik={formik} />
  );
};

export default TutorSignUp;
