import React, { useEffect, useState } from "react";
import ContentContainer from "../../components/common/Portal/ContentContainer";
import { getTutorCredits } from "../../apis/tutor.apis";
import { useNavigate } from "react-router-dom";

const reasons = {
  1: "Viewed Phone number of",
  2: "Viewed Email of",
  3: "Sent message to",
};

const TableRow = ({ row, index }) => {
  const navigate = useNavigate();
  const getThings = (reason) => {
    let array = [1, 2, 3];
    let displayReason = "";
    let link = "";
    if (array.includes(reason)) {
      link = `/student/requirement/${row.studentRequirement?._id}`;
      displayReason = `${reasons[reason]} ${row.studentRequirement?.student.name}`;
    }
    return { link, displayReason };
  };
  const { link, displayReason } = getThings(row.reason);
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{`${row.entryType}`}</td>
      <td>{`${row.entryValue}`}</td>
      <td>{displayReason}</td>
      <td>
        <div>
          <button
            className="btn btn-info"
            onClick={() => {
              navigate(link);
            }}
          >
            View
          </button>
        </div>
      </td>
    </tr>
  );
};

const TableListWrapper = ({ tableHeaders, tableData }) => {
  return (
    <div className="list_table_wrapper">
      <table className="fixed_header">
        <thead>
          <tr>
            {tableHeaders.map((heading) => {
              return <th>{heading}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, index) => {
            return <TableRow key={rowData._id} row={rowData} index={index} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

const TutorCreditLogs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tutorCredits, setTutorCredits] = useState([]);

  const fetchTutorCredits = async () => {
    const { data, isError } = await getTutorCredits();
    if (!isError) {
      setTutorCredits(data.tutorCredits);
    }
  };

  const onLoading = async () => {
    await fetchTutorCredits();
    setIsLoading(false);
  };

  useEffect(() => {
    onLoading();
  }, []);

  return isLoading ? (
    <>
      <ContentContainer>Loading Credit Logs...</ContentContainer>
    </>
  ) : (
    <ContentContainer>
      <TableListWrapper
        tableHeaders={["S.No.", "CR/DR", "Value", "Reason", "Actions"]}
        tableData={tutorCredits}
      />
    </ContentContainer>
  );
};

export default TutorCreditLogs;
