import React, { useContext, useEffect, useState } from "react";
import "../../styles/pages/Tutor/CompleteProfile/style.css";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment/moment";
import DOMPurify from "dompurify";
import LoginPage from "../Login";
import {
  viewTutorProfile,
} from "../../apis/tutor.apis";
import { decreaseStudentCredits } from "../../apis/student.apis";

const ViewProfile = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { authUserData, getAuthUserData, openModal } = useContext(AuthContext);
  const [tutor, setTutor] = useState();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onPhoneClicked = async () => {
    if (phone === "") {
      let params = {
        value: 5,
        reason: 1,
        tutor: tutor._id,
      };
      let res;
      res = await decreaseStudentCredits({}, params);
      if (!res.isError) {
        await getAuthUserData();
      }
    }
  };
  const onEmailClicked = async () => {
    if (email === "") {
      let params = {
        value: 5,
        reason: 2,
        tutor: tutor._id,
      };
      let res;
      res = await decreaseStudentCredits({}, params);
      if (!res.isError) {
        await getAuthUserData();
      }
    }
  };

  const getTutorProfile = async (tutorId) => {
    const { data, isError } = await viewTutorProfile({ tutor: tutorId });
    if (!isError) {
      setTutor(data.tutor);
      if (phone === "" && data.phone && data.phone.mobile) {
        setPhone(`(${data.phone.mobileCode})${data.phone.mobile}`);
      }
      if (email === "" && data.email) {
        setEmail(data.email);
      }
    }
  };

  useEffect(() => {
    if (!searchParams.get("tutor") || searchParams.get("tutor") === "") {
      navigate("/", { replace: true });
    } else {
      if (searchParams.get("tutor") !== "" && authUserData)
        getTutorProfile(searchParams.get("tutor"));
    }
  }, [searchParams.get("tutor"), authUserData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return isLoading ? (
    "Loading"
  ) : searchParams.get("tutor") !== "" && authUserData && tutor ? (
    <div className="container-fluid mt-2 mb-2">
      <div
        className="d-flex p-2 flex-column mx-auto flex-column"
        style={{ width: "80%" }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div>
            <h2 className="d-inline">{`${tutor.name} `}</h2>
            <h4 className="d-inline">{tutor.briefDescription}</h4>
          </div>
          <div id="reviews">No reviews yet</div>
        </div>
        <div className="d-flex justify-content-center">
          <div id="content" className="d-flex " style={{ width: "70%" }}>
            <ul className="list-group list-group-flush pe-4 pt-2">
              <li className="list-group-item pt-0 ps-0">
                <div id="paragraph" className="mb-2">
                  {tutor.introAboutYourself.split("\n").map((string) => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(string + "<br>"),
                        }}
                      />
                    );
                  })}
                </div>
              </li>
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Subjects</h3>
                  <ul className="list-unstyled">
                    {tutor.teachingDetails.map((object) => {
                      return (
                        <li key={object._id}>
                          <h5>
                            {`${object.skill.name} `}
                            <span
                              style={{ fontSize: "14px", color: "#777777" }}
                            >
                              {`(${object.fromLevel.name} - ${object.toLevel.name})`}
                            </span>
                          </h5>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Experience</h3>
                  <ul className="list-unstyled">
                    {tutor.experiences.map((experience) => {
                      let experienceDateString = "";
                      if (experience.startDate) {
                        if (experience.endDate) {
                          experienceDateString = ` (${moment(
                            experience.startDate
                          ).format("MMM-YYYY")} - ${moment(
                            experience.endDate
                          ).format("MMMM-YYYY")})`;
                        } else {
                          experienceDateString = ` (${moment(
                            experience.startDate
                          ).format("MMMM-YYYY")} - till date`;
                        }
                      }
                      return (
                        <li key={experience._id}>
                          <h5>
                            {`${experience.organization} `}
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#777777",
                                fontWeight: "bold",
                              }}
                            >
                              {`${experience.designation}`}{" "}
                            </span>
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#777777",
                              }}
                            >{`${experienceDateString}`}</span>
                          </h5>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
              <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Education</h3>
                  <ul className="list-unstyled">
                    {tutor.qualifications.map((qualification) => {
                      let qualificationDateString = "";
                      if (qualification.startDate) {
                        if (qualification.endDate) {
                          qualificationDateString = ` (${moment(
                            qualification.startDate
                          ).format("MMM-YYYY")} - ${moment(
                            qualification.endDate
                          ).format("MMMM-YYYY")})`;
                        } else {
                          qualificationDateString = ` (${moment(
                            qualification.startDate
                          ).format("MMMM-YYYY")} - till date`;
                        }
                      }
                      return (
                        <li>
                          <h5>
                            {`${qualification.degreeName} `}
                            <span
                              style={{ fontSize: "14px", color: "#777777" }}
                            >
                              {`${qualificationDateString} `}
                            </span>{" "}
                            <span
                              style={{ fontSize: "14px", color: "#777777" }}
                            >
                              {`from ${qualification.institutionName}`}
                            </span>
                          </h5>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
              {/* <li className="list-group-item pt-4 pb-4">
                <div className="heading">
                  <h3 className="text-primary">Fee Details</h3>
                  <ul className="list-unstyled">
                    <li>
                      <h5>
                        {`${tutor.currency} ${tutor.minFee}-${tutor.maxFee}/${
                          tutor.chargesType === "H" ? "hour" : "month"
                        }`}
                      </h5>
                    </li>
                  </ul>
                </div>
              </li> */}
              {/* <li className="list-group-item pt-4">
                <div className="heading">
                  <h3 className="text-primary">Reviews</h3>
                  <ul className="list-unstyled">
                    <li>
                      <h5>
                        English{" "}
                        <span style={{ fontSize: "14px", color: "#777777" }}>
                          Hello Tutors
                        </span>
                      </h5>
                    </li>
                    <li>
                      <h5>
                        English{" "}
                        <span style={{ fontSize: "14px", color: "#777777" }}>
                          Hello Tutors
                        </span>
                      </h5>
                    </li>
                  </ul>
                </div>
              </li> */}
            </ul>
          </div>
          <div id="right-sidebar" style={{ width: "30%" }}>
            <div
              className="p-2 rounded mt-2 w-100"
              style={{ background: "#6C757D" }}
            >
              <div className="d-flex flex-column align-items-center">
                <div>
                  <button
                    className="me-2"
                    onClick={() => {
                      if (email === "") {
                        openModal({
                          title: "Alert",
                          body: "5 credits will be deducted to view tutor email number. Are you sure to continue?",
                          btnLabel: "Continue",
                          btnHandler: onEmailClicked,
                        });
                      }
                    }}
                  >
                    {email ? `${email}` : "Email"}
                  </button>
                  <button
                    onClick={() => {
                      if (phone === "") {
                        openModal({
                          title: "Alert",
                          body: "5 credits will be deducted to view tutor phone number. Are you sure to continue?",
                          btnLabel: "Continue",
                          btnHandler: onPhoneClicked,
                        });
                      }
                    }}
                  >
                    {phone ? `${phone}` : "Phone"}
                  </button>
                </div>
                {/* <div className="mt-2">
                  <button className="me-2">Pay</button>
                  <button>Review</button>
                </div> */}
              </div>
              <div className="bg-white m-2">
                <ul className="list-group">
                  <li className="list-group-item">{`${tutor.city}, ${tutor.state}, ${tutor.country}`}</li>
                  <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Gender: </span>
                      <span>{tutor.gender === "M" ? "Male" : "Female"}</span>
                    </div>
                  </li>
                  {/* <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Heading: </span>
                      <span>Value</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Heading: </span>
                      <span>Value</span>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div>
                      <span style={{ fontWeight: "500" }}>Heading: </span>
                      <span>Value</span>
                    </div>
                  </li>
                  <li className="list-group-item">Morbi leo risus</li>
                  <li className="list-group-item">Porta ac consectetur ac</li>
                  <li className="list-group-item">Vestibulum at eros</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoginPage isNavigate={false} defaultTab={"student"} isShowTabs={false} />
  );
};

export default ViewProfile;
